import { AccountrecoveryComponent } from '../accountrecovery/accountrecovery.component';
import { ValidatorPatternService } from 'src/app/services/validatorPattern.service';
import { GenericDisplaySservice } from 'src/app/services/genericDisplay.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CryptoService } from 'src/app/services/crypto.service';
import { IMemberAccount } from 'src/app/models/IMemberAccount';
import { TokenService } from 'src/app/services/token.service';
import outputmessage from 'src/assets/messagetokens.json';
import { AuthService } from '../../services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
    selector: 'signin',
    templateUrl: './signin.component.html',
    styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {

    signInForm: UntypedFormGroup;
    errorMessage: string;

    @Output() connectedAccount: EventEmitter<IMemberAccount> = new EventEmitter<IMemberAccount>();

    constructor(
        private validatorPatternService: ValidatorPatternService,
        private genericDisplaySservice: GenericDisplaySservice,
        private formBuilder: UntypedFormBuilder,
        private cryptoService: CryptoService,
        private tokenService: TokenService,
        public authService: AuthService, 
        private dialog: MatDialog,
        private router: Router,
    ) {}

    ngOnInit() {
        localStorage.setItem('io-language', 'de');

        this.signInForm = this.formBuilder.group({
            username: ['', [Validators.required, Validators.pattern(this.validatorPatternService.regexOfUsername)]],
            password: ['', [Validators.required]]
        });

        const user: any = JSON.parse(this.tokenService.getLoggedInUser());
        if (user !== null) {
            this.connectedAccount.emit({
                isAdmin: user.isAdmin,
                isMarketer: user.isMarketer,
                isMember: user.accounttype === 'Mitglied'
            });
            this.router.navigateByUrl('customerdata');
        }
    }

    onSubmit() {
        const username = this.signInForm.get('username').value;
        const password = this.signInForm.get('password').value;

        this.authService.signInUser(username, password).subscribe((response) => {

            this.authService.isConnected = response !== null && response !== undefined && response.jwt;
            this.signInForm.get('password').reset();

            this.authService.isAdminUser.subscribe((isadminResponse) => {
                if (isadminResponse) {
                    this.router.navigateByUrl('datachangeprocessing');
                }
                else {
                    this.router.navigateByUrl('customerdata');
                }

                const konto: IMemberAccount = {
                    isAdmin: this.cryptoService.decryptData(response.isAdmin),
                    isMarketer: this.cryptoService.decryptData(response.isMarketer),
                    isMember: this.cryptoService.decryptData(response.accounttype) === 'Mitglied'

                };
                this.connectedAccount.emit(konto);
            }, (isadminError) => {

                this.errorMessage = isadminError;
                if (isadminError.message) {
                    this.errorMessage = isadminError.message;
                }
                this.genericDisplaySservice.showAlert('Warnung', this.errorMessage);
            });

        }, (errorResponse) => {
            if (outputmessage && outputmessage.unknownUser) {
                this.genericDisplaySservice.showAlert(errorResponse, outputmessage.unknownUser.replace('[[username]]', username));
            }
        });
    }

    updateFormValue(event: Event & { detail?: string, target: { id: string } }) {
        this.signInForm.controls[event.target.id].setValue(event.detail);
    }

    popupAccountrecoveryComponent() {
        this.dialog.open(AccountrecoveryComponent, {
            height: '230px',
            width: '500px',
        });
    }
}

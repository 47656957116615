
import { CustomerService } from './customer.service';
import { HttpClient } from '@angular/common/http';
import { TokenService } from './token.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { jwtokenState } from '../enumerations/jwtokenState.enum';
import { environment } from 'src/environments/environment';
import { MarketerService } from './marketer.service';
import { ValidatorPatternService } from './validatorPattern.service';
import { MemberService } from './member.service';
import { CryptoService } from './crypto.service';
import { StringFunctionsService } from './stringFunctions.service';
import { accountypeDefinitions } from '../enumerations/accounttypeDefinitions.enum';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    isConnected = false;
    private apiurl = environment.apiRootDirectory;

    //Verhaltenssubjekt des eingeloggten Users
    private userHasOA = new BehaviorSubject<boolean>(false);
    //Verhaltenssubjekt des eingeloggten Users
    private loggedIn = new BehaviorSubject<boolean>(false);
    //Verhaltenssubjekt des eingeloggten Admin Users
    private isAdminloggedIn = new BehaviorSubject<boolean>(false);
    //Verhaltenssubjekt des eingeloggten Vermarkter Users
    private isMarketerloggedIn = new BehaviorSubject<boolean>(false);
    //Verhaltenssubjekt des eingeloggten konto-Ids
    private connectedUser = new BehaviorSubject<string>(null);
    //Verhaltenssubjekt des eingeloggten Kontos
    private isMobilloggedIn = new BehaviorSubject<boolean>(false);
    //Verhaltenssubjekt des eingeloggten konto-Ids
    private usertooltip = new BehaviorSubject<string>(null);
    //Verhaltenssubjekt des eingeloggten Kontos
    private __isAdvertisingprovider = new BehaviorSubject<boolean>(false);
    private __ismemberAMarkter = new BehaviorSubject<boolean>(false);

    constructor(private _http: HttpClient, private router: Router, private tokenService: TokenService,
        private customerService: CustomerService, private validatorService: ValidatorPatternService,
        private marketerService: MarketerService, private stringService: StringFunctionsService,
        private memberService: MemberService, private crypto: CryptoService) { }


    private setUserConnectivity(user: any) {
        //für appComponent
        this.loggedIn.next(true);
        //für auth-Guard
        this.isConnected = true;
        //für Header
        this.connectedUser.next(this.crypto.decryptData(user.username));
        // Laden des principal domains
        this.customerService.loadprincipals();
        // Laden der Vermaktergesellschaft
        this.marketerService.loadmemberOfGroup();

        // set ob User ein OA hat
        this.userHasOA.next(user.hasOA);

        //handle ob der User ein Admin ist
        if (user !== null && user !== undefined) {
            // set ob User ein Admin ist
            this.isAdminloggedIn.next(user.isAdmin);
            // set ob User ein Vermarkter ist
            this.isMarketerloggedIn.next(user.isMarketer);
            // set ob User ein mobiles User ist
            this.isMobilloggedIn.next(user.isMobilUser);
            // usertooltip
            const acountData: string[] = [];
            // Benutzerkategorie
            if (!this.stringService.isUndefinedNullOrEmpty(user.accounttype)) {
                const accounttype: string = this.crypto.decryptData(user.accounttype);
                if (!this.stringService.isUndefinedNullOrEmpty(accounttype)) {
                    if (accounttype.toLowerCase() === 'vgm')
                        acountData.push(accountypeDefinitions.VGM);
                    else if (accounttype.toLowerCase() === 'operator')
                        acountData.push(accountypeDefinitions.operator);
                    else if (accounttype.toLowerCase() === 'mitglied')
                        acountData.push(accountypeDefinitions.member);
                    else// ursprunglicher Konttotyp eintragen
                        acountData.push(user.accounttype);
                } else {
                    // ursprunglicher Konttotyp eintragen
                    acountData.push(user.accounttype);
                }
            }
            // Benutzeremail
            if (!this.stringService.isUndefinedNullOrEmpty(user.email))
                acountData.push(this.crypto.decryptData(user.email));
            // usertooltip bilden
            this.usertooltip.next(acountData.join(' | '));
            // Werbungsträger
            this.__isAdvertisingprovider.next(user.isAvdertisingprovider);
        }
    }

    /**
   * gib an ob ein Mitglied ein Werbungsträger ist
   * @param principaldomain Mitgliedskürzel
   */
    public setIfMemberIsAdvertisingprovider(principaldomain: string) {
        if (principaldomain === null)
            this.__isAdvertisingprovider.next(false);
        else
            this.memberService.readMember(principaldomain).subscribe((member) => {
                this.__isAdvertisingprovider.next(member.isAvdertisingprovider);
            });
    }

    // setzt, ob ein Mitglied ein Vermarkter  ist
    public setIfMemberIsMarketer(value: boolean) {
        this.__ismemberAMarkter.next(value);
    }

    // gib an, ob ein Mitglied ein Vermarkter  ist
    public get memberIsMarketer(): Observable<boolean> {
        return this.__ismemberAMarkter.asObservable();
    }

    // gib an, ob ein Mitglied ein Werbungsträger  ist
    get isUserAAdvertisingprovider(): Observable<boolean> {
        return this.__isAdvertisingprovider.asObservable();
    }

    // lies das Tooltip des User-Accounts
    get Usertooltip(): Observable<string> {
        return this.usertooltip.asObservable();
    }

    // gib an, ob ein Mitglied ein Onlineangebot hat
    get hasUserOA(): Observable<boolean> {
        if (this.tokenService.jwtokenExists() && this.tokenService.isJwtTokenValid()) {
            const user: any = JSON.parse(this.tokenService.getLoggedInUser());
            if (user)
                this.setUserConnectivity(user);
        }
        return this.userHasOA.asObservable();
    }

    // gib an, ob ein Mitglied ein moliber User  ist
    get isUserAMobilUser(): Observable<boolean> {
        if (this.tokenService.jwtokenExists() && this.tokenService.isJwtTokenValid()) {
            const user: any = JSON.parse(this.tokenService.getLoggedInUser());
            if (user)
                this.setUserConnectivity(user);
        }
        return this.isMobilloggedIn.asObservable();
    }

    // gib an, ob ein Mitglied eingeloggt  ist
    get isUserLoggedIn(): Observable<boolean> {
        if (this.tokenService.jwtokenExists() && this.tokenService.isJwtTokenValid()) {
            const user: any = JSON.parse(this.tokenService.getLoggedInUser());
            if (user)
                this.setUserConnectivity(user);
        }
        return this.loggedIn.asObservable();
    }

    // liefert der Username des eingeloggten Mitglieds zurück
    get usernameLogged(): string {
        const user: any = JSON.parse(this.tokenService.getLoggedInUser());
        if (user && user.username)
            return user.username;
        return null;
    }

    // liefert der eingeloggte User zurück
    get loggedUser(): Observable<string> {
        return this.connectedUser.asObservable();
    }

    // gib an, ob der User ein admin ist
    get isAdminUser(): Observable<boolean> {
        return this.isAdminloggedIn.asObservable();
    }

    // gib an, ob der User ein Vermarkter ist
    get isMarkterUser(): Observable<boolean> {
        return this.isMarketerloggedIn.asObservable();
    }

    /**
   * legt ein User an
   * @param principalDomain, Mitgliedskürzel 
   * @param login, Username 
   * @param email, E-Mail-Adresse 
   * @param isMobilUser, ob es sich um ein mobiles Mitglied handelt 
   * @param accounttype, Kontotyp 
   */
    createUser(principalDomain: string, login: string, email: string, isMobilUser: string, accounttype: string) {
        return this._http.post<any>(this.apiurl + 'data/user/create.php', { 'principalDomain': principalDomain, 'login': login, 'email': email, 'isMobilUser': isMobilUser, 'accounttype': accounttype });
    }

    /**
    * gib an, ob ein JWT Token noch valid ist
    */
    isUserTokenStillValid(): Observable<boolean> {
        const subject = new Subject<boolean>();
        // clientseitige-Prüfung der Gültigkeit des Tokens
        if (this.tokenService.isJwtTokenValid()) {
            // serverseitige-Prüfung der Gültigkeit des Tokens
            this.tokenService.isTokenValid().subscribe((tokenReponse) => {
                if ((tokenReponse.message === jwtokenState.granted)) {
                    subject.next(true);
                } else {
                    subject.next(false);
                    this.signOutUser();
                }
            });
        }
        return subject.asObservable();
    }

    /**
    * Promise gib an, ob ein JWT Token noch valid ist 
    */
    async p_isUserTokenStillValid() {
        return await this.isUserTokenStillValid().toPromise();
    }


    /**
    * generiert und versendet ein Passwort
    * @param username, Username
    */
    generateAndSendNewcomerPassword(username: string) {
        // Post
        return this._http.post<any>(this.apiurl + 'mails/newUserPasswordMail.php', { 'username': username });
    }

    /**
    * fordert das Passwort an
    * @param username, Username
    */
    sendResetPasswordRequest(username: string) {
        // Post
        return this._http.post<any>(this.apiurl + 'mails/passwordForgottenMail.php', { 'username': username });
    }

    /**
    * 
    * @param username, Username 
    * @param oldpassword, altes Passwort 
    * @param newpassword, neues Passwort 
    */
    changePassword(username: string, oldpassword: string, newpassword: string) {
        // Post
        return this._http.post<any>(this.apiurl + 'auth/changePassword.php', { 'username': username, 'oldpassword': oldpassword, 'newpassword': newpassword });
    }


    /**
   *  log den User ein
   * @param username, Username 
   * @param password, Passwort 
   */
    signInUser(username: string, password: string) {

        // Post
        return this._http.post<any>(this.apiurl + 'auth/login.php', { 'username': username, 'password': password })
            .pipe(map(user => {
                // login successful if there"s a jwt token in the response
                if (user) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    this.tokenService.setTokenInStorage(user);

                    this.setUserConnectivity(user);

                }
                return user;
            }));
    }

    // log den user aus
    signOutUser() {
        const user: any = JSON.parse(this.tokenService.getLoggedInUser());
        if (user)
            // Zeitstemmpel der Abmeldung eintragen
            this.setConnectionTrace(user.username).subscribe(() => {
                //
            });

        // alle Keys löschen
        localStorage.clear();
        this.isConnected = false;

        //für HeaderComponent webkatalog
        this.setIfMemberIsAdvertisingprovider(null);

        // für HeaderComponent, Vgg dropdown
        this.setIfMemberIsMarketer(false);

        //für HeaderComponent
        this.loggedIn.next(false);

        //für HeaderComponent
        this.usertooltip.next('');

        // zum Login
        this.router.navigate(['/']);
    }

    setConnectionTrace(username: string) {
        return this._http.post<any>(this.apiurl + 'auth/logout.php', { 'username': username });
    }

    /**
   * Validierung des Passwortes, die Zeichenlänge wird auch geprüft
   * @param password 
   */
    validateStrengthPassword(password: string): boolean {
        // Validate password strength    
        const containslowercase = /([a-z])/.test(password);
        const containsuppercase = /([A-Z])/.test(password); //no repeating /^([A-Z])/
        const containsnumber = /([0-9])/.test(password);
        const containsspecialChars = /([!@§ß#$%^&*()\\/_=+;:,.?-])/.test(password);
        const containsLeastChars = password.length >= this.validatorService.getPasswordMinLength && password.length <= this.validatorService.getPasswordMaxLength;

        return containslowercase && containsuppercase && containsnumber && containsspecialChars && containsLeastChars;
    }

}

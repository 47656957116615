import { userDataChangesdataCategorieIds } from '../enumerations/userDataChangesdataCategorieIds.enum';
import { ICustomerdataControlsIdValuePair } from '../models/ICustomerdataControlsIdValuePair.model';
import { userDataChangesState } from '../enumerations/userDataChangesState.enum';
import { StringFunctionsService } from './stringFunctions.service';
import { UserDataChanges } from '../models/UserDataChanges.model';
import { contactTypes } from '../enumerations/contactTypes.enum';
import { DataComparatorService } from './dataComparator.service';
import { Field } from '../models/Field.model';
import { Injectable } from '@angular/core';


/**
 * Der Service spricht gleicht die Firmendaten ab
 */

@Injectable({
    providedIn: 'root'
})
export class CustomerDatacomparatorService {

    constructor(private stringService: StringFunctionsService,
        private dataComparatorService: DataComparatorService) { }

    //zur Sammlung der Änderungen der Kontaktdaten
    resultChangedContactfields: Field[] = [];
    //zur Sammlung der Änderungen der Firmendaten
    resultChangedCompagnyfields: Field[] = [];

    /**
     * fügt eine Kontaktdaten basierte Änderung hinzu
     * @param field, Bezugsfeld-Objekt
     */
    private addChangesByFieldRelatedToContactdata(field: Field) {

        if (field !== null) {
            this.resultChangedContactfields.push(field);
        }
    }

    /**
     * fügt eine Firmendaten basierte Änderung hinzu
     * @param field, Bezugsfeld-Objekt
     */
    private addChangesByFieldRelatedToCompanydata(field: Field) {
        if (field !== null) {
            this.resultChangedCompagnyfields.push(field);
        }
    }

    /**
     * gleich die Kontaktdaten der Organisation ab
     * @param oldOrgadata, aktuelle Daten
     * @param newChangedData, neue Daten 
     * @param pendingchanges, noch nicht genehmigte Daten 
     */
    handleOrgaContact(oldOrgadata: any, newChangedData: any, pendingchanges: ICustomerdataControlsIdValuePair) {
        if (oldOrgadata === undefined || oldOrgadata === null)
            oldOrgadata = {};
        this.addChangesByFieldRelatedToContactdata(this.dataComparatorService.compareContactFieldValues(oldOrgadata.firstname, newChangedData.orga_contactname, pendingchanges.orga_contactname, contactTypes.organisation, 'firstname', 'Vorname'));
        this.addChangesByFieldRelatedToContactdata(this.dataComparatorService.compareContactFieldValues(oldOrgadata.lastname, newChangedData.orga_contactlastname, pendingchanges.orga_contactlastname, contactTypes.organisation, 'lastname', 'Nachname'));
        this.addChangesByFieldRelatedToContactdata(this.dataComparatorService.compareContactFieldValues(oldOrgadata.email, newChangedData.orga_contactemail, pendingchanges.orga_contactemail, contactTypes.organisation, 'email', 'E-Mail'));
        this.addChangesByFieldRelatedToContactdata(this.dataComparatorService.compareContactFieldValues(oldOrgadata.phone, newChangedData.orga_contactphone, pendingchanges.orga_contactphone, contactTypes.organisation, 'phone', 'Telefon'));
    }

    /**
     * gleich die Kontaktdaten der Technik ab
     * @param oldTechdata, aktuelle Daten
     * @param newChangedData, neue Daten 
     * @param pendingchanges, noch nicht genehmigte Daten 
     */
    handletechContact(oldTechdata: any, newChangedData: any, pendingchanges: ICustomerdataControlsIdValuePair) {

        if (oldTechdata === undefined || oldTechdata === null)
            oldTechdata = {};

        this.addChangesByFieldRelatedToContactdata(this.dataComparatorService.compareContactFieldValues(oldTechdata.firstname, newChangedData.tech_contactname, pendingchanges.tech_contactname, contactTypes.technical, 'firstname', 'Vorname'));
        this.addChangesByFieldRelatedToContactdata(this.dataComparatorService.compareContactFieldValues(oldTechdata.lastname, newChangedData.tech_contactlastname, pendingchanges.tech_contactlastname, contactTypes.technical, 'lastname', 'Nachname'));
        this.addChangesByFieldRelatedToContactdata(this.dataComparatorService.compareContactFieldValues(oldTechdata.email, newChangedData.tech_contactemail, pendingchanges.tech_contactemail, contactTypes.technical, 'email', 'E-Mail'));
        this.addChangesByFieldRelatedToContactdata(this.dataComparatorService.compareContactFieldValues(oldTechdata.phone, newChangedData.tech_contactphone, pendingchanges.tech_contactphone, contactTypes.technical, 'phone', 'Telefon'));
    }


    /**
     * gleich die Kontaktdaten des Prüfers ab
     * @param oldAuditreportdata, aktuelle Daten
     * @param newChangedData, neue Daten 
     * @param pendingchanges, noch nicht genehmigte Daten 
     */
    handlePruefContact(oldAuditreportdata: any, newChangedData: any, pendingchanges: ICustomerdataControlsIdValuePair) {
        if (oldAuditreportdata === undefined || oldAuditreportdata === null)
            oldAuditreportdata = {};

        this.addChangesByFieldRelatedToContactdata(this.dataComparatorService.compareContactFieldValues(oldAuditreportdata.firstname, newChangedData.pruef_contactname, pendingchanges.pruef_contactname, contactTypes.auditreport, 'firstname', 'Vorname'));
        this.addChangesByFieldRelatedToContactdata(this.dataComparatorService.compareContactFieldValues(oldAuditreportdata.lastname, newChangedData.pruef_contactlastname, pendingchanges.pruef_contactlastname, contactTypes.auditreport, 'lastname', 'Nachname'));
        this.addChangesByFieldRelatedToContactdata(this.dataComparatorService.compareContactFieldValues(oldAuditreportdata.email, newChangedData.pruef_contactemail, pendingchanges.pruef_contactemail, contactTypes.auditreport, 'email', 'E-Mail'));
        this.addChangesByFieldRelatedToContactdata(this.dataComparatorService.compareContactFieldValues(oldAuditreportdata.phone, newChangedData.pruef_contactphone, pendingchanges.pruef_contactphone, contactTypes.auditreport, 'phone', 'Telefon'));
    }

    /**
     * gleich die Firmentdaten ab
     * @param oldcompanydata, aktuelle Daten
     * @param newChangedData, neue Daten 
     * @param pendingchanges, noch nicht genehmigte Daten 
     */
    handleCompanydata(oldcompanydata: any, newChangedData: any, pendingchanges: ICustomerdataControlsIdValuePair) {
        if (oldcompanydata === undefined || oldcompanydata === null)
            oldcompanydata = {};

        this.addChangesByFieldRelatedToCompanydata(this.dataComparatorService.compareFieldValues(oldcompanydata.name, newChangedData.customername, pendingchanges.customername, 'name', 'Name'));
        this.addChangesByFieldRelatedToCompanydata(this.dataComparatorService.compareFieldValues(oldcompanydata.street, newChangedData.street, pendingchanges.street, 'street', 'Strasse'));
        this.addChangesByFieldRelatedToCompanydata(this.dataComparatorService.compareFieldValues(oldcompanydata.zipcode, newChangedData.zipcode, pendingchanges.zipcode, 'zipcode', 'PLZ'));
        this.addChangesByFieldRelatedToCompanydata(this.dataComparatorService.compareFieldValues(oldcompanydata.city, newChangedData.city, pendingchanges.city, 'city', 'Stadt'));
    }


    /**
     * Datenangleich der allgemeinen Informationen
     * @param principalDomain, Mitgliedskürzel 
     * @param newChangedData, neue Firmendaten 
     * @param oldCompanydata, alte Firmendaten
     * @param oldContactsOfCompany, alte Kontaktdaten der Firma 
     * @param pendingchanges 
     */
    compareCustomerdata(principalDomain: string, newChangedData: any, oldCompanydata: any, oldContactsOfCompany: any, pendingchanges: ICustomerdataControlsIdValuePair): UserDataChanges[] {

        this.resultChangedCompagnyfields = [];
        this.resultChangedContactfields = [];

        const dataChangesResult: UserDataChanges[] = [];
        const companytablename = 'company';
        // Firmendaten abgleichen
        this.handleCompanydata(oldCompanydata, newChangedData, pendingchanges);
        // falls eine Änderung vorliegt
        if (this.resultChangedCompagnyfields.length > 0) {
            //offername und tableFieldidentity bleiben leer da wir sie bei Firmendaten nicht brauchen
            dataChangesResult.push(new UserDataChanges(principalDomain, userDataChangesdataCategorieIds.compagnydata, this.stringService.DateNowToString(), companytablename, userDataChangesState.pending, this.resultChangedCompagnyfields));
        }


        // Daten von diversen Kontakten abgleichen         
        let oldContactOrga = null;
        let oldContactPruef = null;
        let oldContactTech = null;
        const contacttablename = 'contacts';
        const contacttableFieldidentity = 'contacttypeId';
        if (!this.stringService.isUndefinedOrNull(oldContactsOfCompany) && !this.stringService.isUndefinedOrNull(oldContactsOfCompany['data'])) {
            for (const contact of oldContactsOfCompany['data']) {
                // Organisation
                if (contact.contacttypeId.toString() === contactTypes.organisation.toString()) {
                    oldContactOrga = contact;
                }
                // Technik
                else if (contact.contacttypeId.toString() === contactTypes.technical.toString()) {
                    oldContactTech = contact;
                }
                // Prüfer
                else if (contact.contacttypeId.toString() === contactTypes.auditreport.toString()) {
                    oldContactPruef = contact;
                }
            }
        }

        // Daten der Orga abgleichen
        this.handleOrgaContact(oldContactOrga, newChangedData, pendingchanges);
        // Daten der Tech abgleichen
        this.handletechContact(oldContactTech, newChangedData, pendingchanges);
        // Daten des Prüfers abgleichen
        this.handlePruefContact(oldContactPruef, newChangedData, pendingchanges);
        // falls eine Änderung vorliegt
        if (this.resultChangedContactfields.length > 0) {
            //"offername": "", bleibt leer da wir bei Allgemeine Infos, kein Angebotsdaten verwalten
            dataChangesResult.push(new UserDataChanges(principalDomain, userDataChangesdataCategorieIds.compagnydata, this.stringService.DateNowToString(), contacttablename, userDataChangesState.pending, this.resultChangedContactfields, contacttableFieldidentity));
        }


        return dataChangesResult;

    }


}
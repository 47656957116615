<h1 mat-dialog-title mat-dialog-draggable-title class="text-primary" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{title}}
    <button type="button" class="close" (click)="cancelOption()" data-dismiss="modal" aria-hidden="true">×</button>
</h1>
<div class="box">
<!--
    <h2>
        <p align="center">
            <span class="title">{{title}}</span></p>
    </h2>
-->
    <main>
        <br>
        <p align="center" *ngIf="screen==='confirmation'">
            <i class="fa fa-check-circle-o" aria-hidden="true" style="color:green"></i>&nbsp;&nbsp;{{message}}

        </p>
        <p align="center" *ngIf="screen==='warning'">
            <i class="fa fa-exclamation-triangle fa-2x" aria-hidden="true" style="color:red"></i>&nbsp;&nbsp;{{message}}
        </p>
        

        <br>
     
        <br>
    </main>
    <footer>
        <p align="center">
            <button class="btn btn-outline-primary" (click)="cancelOption()">&nbsp;Schliessen</button></p>
    </footer>
</div>

import { DocumentService } from 'src/app/services/document.service';
import { SafeResourceUrl } from '@angular/platform-browser';
import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'pdfcontract',
    templateUrl: './pdfcontract.component.html',
    styleUrls: ['./pdfcontract.component.scss']
})
export class PdfcontractComponent implements OnInit {
    errorMessage: string;
    page = 1;
    pdfSrc: SafeResourceUrl;

    constructor(private authService: AuthService, private router: Router, private docService: DocumentService) { }

    ngOnInit() {
        this.authService.isUserTokenStillValid().subscribe(isTokenValid => {
            if (isTokenValid) {
                this.docService.downloadAdditionalAgreementPdf().subscribe((downloadresponse) => {

                    const blob: Blob = new Blob([downloadresponse], { type: 'application/pdf' });

                    const objectUrl = URL.createObjectURL(blob);
                    // Route zurücksetzen
                    this.router.navigateByUrl('blank').then(() => {
                        this.router.navigate(['agreementpreview']);
                    });
                    //download in neue Tab öffnen
                    window.open(objectUrl, '_blank');
                });          
            }
        });
    }
}

import { ChangesFieldGroupedByPrincipalAndOffer, offerGroup, ChangesFieldGroupedByDate } from '../models/ChangesFieldGroupedList.model';
import { userDataChangesdataCategorieIds } from '../enumerations/userDataChangesdataCategorieIds.enum';
import { userDataChangesState } from '../enumerations/userDataChangesState.enum';
import { ChangedFieldObject } from '../models/ChangedFieldObject.model';
import { StringFunctionsService } from './stringFunctions.service';
import { UserDataChanges } from '../models/UserDataChanges.model';
import { GenericDisplaySservice } from './genericDisplay.service';
import { changesTypes } from '../enumerations/changeTypes.enum';
import { environment } from 'src/environments/environment';
import outputmessage from 'src/assets/messagetokens.json';
import { ContainerService } from './container.service';
import { SqlQueryService } from './sqlQuery.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CryptoService } from './crypto.service';
import { Field } from '../models/Field.model';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { sort } from 'fast-sort';

/**
 * Der Service spricht der API um den Datenänderungen an
 */

@Injectable({
    providedIn: 'root'
})
export class UserDataChangesService {

    private apiurl = environment.apiRootDirectory;

    /**
     * Konstruktor
     * @param _http Http Client
     * @param router, Navigationshändler
     * @param sqlService, Service für die Erstellung von SQL-Abfragen 
     * @param containerService, Service für die Container-Routinen
     * @param stringService, String Service 
     * @param genericDisplayService, Service für die Warnungsanzeige 
     * @param cryptoService, Service für die Verschlüsselung 
     */
    constructor(private _http: HttpClient, private router: Router, private sqlService: SqlQueryService,
        private containerService: ContainerService, private stringService: StringFunctionsService,
        private genericDisplayService: GenericDisplaySservice,
        private cryptoService: CryptoService
    ) { }

    /**
     * rollback einer Änderung
     * @param pendingchangesbyDataCategorie, noch nicht genehmigte Daten pro Datenkategorie
     * @param singlefieldDatacategorieId, Datenkategorie Id des Feld wo ein Rollback ausgefürht werden soll 
     * @param singlefieldTablename, Tabellenname 
     * @param singlefieldPrincipaldomain, Mitgliedskürzel 
     * @param singlefieldOffername, Angebotsname 
     * @param field, Das Feld, wo ein Rollback ausgefürht werden soll 
     * @param route, das Route-Objekt
     */
    TryRollbackChange(pendingchangesbyDataCategorie: any, singlefieldDatacategorieId: string, singlefieldTablename: string, singlefieldPrincipaldomain: string, singlefieldOffername: string, field: Field, route: string[]) {

        // noch nicht bestätigter Ausweisungswunsch laden
        this.foundIfSingleUserChangeAlreadyExists(pendingchangesbyDataCategorie, singlefieldDatacategorieId,
            singlefieldTablename,
            singlefieldPrincipaldomain,
            singlefieldOffername, field).subscribe((userDataChangesId) => {

            if (userDataChangesId !== null && userDataChangesId !== undefined) {
                //Status des Datensatzes ändern  userDataChangesState.pending zu refused          
                this.refuseChangeByQuery(this.sqlService.getProcessedChangeSqlQuery(userDataChangesId, userDataChangesState.refused)).subscribe(

                    (response) => {

                        if (response.success)

                            if (route !== null && route.length > 0)
                            // Refresh page nach der Löschung der Änderung aus dem Admin bereich
                                this.router.navigateByUrl('blank').then(() => {
                                    this.router.navigate(route);
                                });
                    }
                );
            }
        });
    }
    /**
     * 
     * @param pendingchangesbyDataCategorie, noch nicht genehmigte Daten pro Datenkategorie 
     * @param datacategorieId, Datenkategorie Id des Feld wo ein Rollback ausgefürht werden soll  
     * @param tablename, Tabellenname 
     * @param principaldomain, Mitgliedskürzel 
     * @param offername, Angebotsname 
     * @param fieldname, Feldname 
     * @param fielddescription, Feldbeschreibung
     */
    offerHasPendingchangeOnField(pendingchangesbyDataCategorie: any, datacategorieId: string, tablename: string, principaldomain: string, offername: string, fieldname: string, fielddescription: string) {

        if (pendingchangesbyDataCategorie && pendingchangesbyDataCategorie['data'])

            for (const pendingchange of pendingchangesbyDataCategorie['data']) {

                if (pendingchange.dataCategorieId === datacategorieId &&
                    pendingchange.changedFieldDesc === fielddescription &&
                    pendingchange.fieldname === fieldname &&
                    pendingchange.tablename === tablename &&
                    pendingchange.principalDomain === principaldomain &&
                    ((this.stringService.isUndefinedNullOrEmpty(pendingchange.offername) && this.stringService.isUndefinedNullOrEmpty(offername)) ||
                        ((!this.stringService.isUndefinedNullOrEmpty(pendingchange.offername) && !this.stringService.isUndefinedNullOrEmpty(offername) &&
                            pendingchange.offername === offername)
                        ))) {
                    return pendingchange.id;
                }
            }
        return null;
    }

    /**
        * prüft ob eine solche Änderung bei diesem Feld bei diesem Angebot schon vorliegt
        * @param pendingchangesbyDataCategorie, noch nicht bestätigte Änderungen des Kunden         
        * @param singlefieldDatacategorieId, Datenkategorie-Id 
        * @param singlefieldTablename, Tabellenname 
        * @param singlefieldPrincipaldomain, Mitgliedskürzel 
        * @param singlefieldOffername, Offername 
        * @param singlefield, Feldname-Objekt 
     */
    foundIfSingleUserChangeAlreadyExists(pendingchangesbyDataCategorie: any, singlefieldDatacategorieId: string, singlefieldTablename: string, singlefieldPrincipaldomain: string, singlefieldOffername: string, singlefield: Field): Observable<string> {

        const id = new BehaviorSubject<string>(null);

        // Datenänderungen liegen vor
        if (pendingchangesbyDataCategorie && pendingchangesbyDataCategorie['data'])

            for (const pendingchange of pendingchangesbyDataCategorie['data']) {

                if (pendingchange.dataCategorieId === singlefieldDatacategorieId &&
                    pendingchange.changedFieldDesc === singlefield.changedFieldDesc &&
                    pendingchange.fieldname === singlefield.fieldname &&
                    pendingchange.tablename === singlefieldTablename &&
                    pendingchange.principalDomain === singlefieldPrincipaldomain &&
                    ((this.stringService.isUndefinedNullOrEmpty(pendingchange.offername) && this.stringService.isUndefinedNullOrEmpty(singlefieldOffername)) ||
                        ((!this.stringService.isUndefinedNullOrEmpty(pendingchange.offername) && !this.stringService.isUndefinedNullOrEmpty(singlefieldOffername) &&
                            pendingchange.offername === singlefieldOffername)
                        ))) {

                    // bei dem Feld ftp_password, handelt sich um ein hash. daher müssen die entschlüsselten Werte vergliechen
                    if (singlefield.fieldname === 'ftp_password') {
                        // falls die beide hash gleich sind
                        if (this.cryptoService.decryptData(pendingchange.oldvalue) === this.cryptoService.decryptData(singlefield.oldvalue))
                            id.next(pendingchange.id);

                    }
                    // bei Kontaktänderungen muss moch geprüft werden ob die fieldidentValue gleich sind
                    if (pendingchange.tablename === 'contacts') {
                        if (pendingchange.fieldidentValue === singlefield.fieldidentityValue) {
                            id.next(pendingchange.id);

                        }

                    } else {
                        // Rollback handeln
                        // noch nicht genehmigte Daten liegt vor und der User möchte dieser entfernen
                        if (!this.stringService.isUndefinedNullOrEmpty(pendingchange.newvalue) && singlefield.newvalue === changesTypes.rollback)
                            id.next(pendingchange.id);
                        else if (((this.stringService.isUndefinedNullOrEmpty(pendingchange.oldvalue) && this.stringService.isUndefinedNullOrEmpty(singlefield.oldvalue)) ||
                            ((!this.stringService.isUndefinedNullOrEmpty(pendingchange.oldvalue) && !this.stringService.isUndefinedNullOrEmpty(singlefield.oldvalue) &&
                                pendingchange.oldvalue === singlefield.oldvalue))))
                            id.next(pendingchange.id);
                    }
                }
            }
        return id.asObservable();
    }

    /**
     * speichert die Datenänderungen
     * @param dataChange Änderungsinformationen
     * @param field Feld-Objekt was geändert wurde und die gespeichert werden muss
     * @param userchangeId ID der noch nicht bestätigten Änderung
     */
    insertOrUpdateDataChanges(dataChange: UserDataChanges, field: Field, userchangeId: string) {

        return this._http.post<any>(this.apiurl + 'data/userDataChanges/create.php', {
            'principalDomain': dataChange.principalDomain,
            'offername': dataChange.offername,
            'dataCategorieId': dataChange.dataCategorieId,
            'changeDate': dataChange.changeDate,
            'tablename': dataChange.tablename,
            'processed': dataChange.processed,
            'changedFieldDesc': field.changedFieldDesc,
            'fieldname': field.fieldname,
            'oldvalue': field.oldvalue,
            'newvalue': field.newvalue,
            'fieldidentity': dataChange.tableFieldidentity,
            'fieldidentValue': field.fieldidentityValue,
            'offerRowId': dataChange.offerRowId,
            'userchangeId': userchangeId,
            'newvalueBeforeHash': field.newvalueBeforeHash

        });
    }

    /**
     * lädt die Änderungen nach Username
     * @param principalDomain, Mitgliedskürzel
     */
    selectDataChanges(principalDomain?: string) {
        if (principalDomain !== null && principalDomain !== undefined) {
            return this._http.post<ChangedFieldObject[]>(this.apiurl + 'data/userDataChanges/readAllByCustomer.php', { 'principalDomain': principalDomain });
        }
        return this._http.get<ChangedFieldObject[]>(this.apiurl + 'data/userDataChanges/readAllByCustomer.php');
    }

    /**
     * lädt die Änderungen nach Username
     * @param principalDomain, Mitgliedskürzel 
     *  @param dataCategorieId, Datenkategorie 
     *  @param offername, Angebotsname 
     */
    selectPendingDataChangesByOfferAndDataCategorie(principalDomain: string, dataCategorieId: string, offername: string) {

        return this._http.post<ChangedFieldObject[]>(this.apiurl + 'data/userDataChanges/readByCustommerDataCategorie.php',
            {
                'principalDomain': principalDomain,
                'dataCategorieId': dataCategorieId,
                'offername': offername
            });
    }   

    /**
     * gruppiert die Änderungsdaten nach Datenkategorien
     * @param entries 
     */
    groupChangedFieldObjectToList(entries: ChangedFieldObject[]): ChangesFieldGroupedByPrincipalAndOffer[] {
        const groupedEntries = [] as ChangesFieldGroupedByPrincipalAndOffer[];
        
        for (const entry of entries) {
            if (!groupedEntries.some(groupedEntry => groupedEntry.principalDomain === entry.principalDomain)) {
                groupedEntries.push({
                    principalDomain: entry.principalDomain,
                    customerdataChangesfields: entry.dataCategorieId === '1' ? [entry] : [],
                    offerdataChangesfields: entry.dataCategorieId === '2' ? [{
                        offername: entry.offername,
                        websitename: entry['websitename'],
                        groupedDataChangesfields: [entry]
                    }] : [],
                    localdataChangesfields: entry.dataCategorieId === '3' ? [{
                        offername: entry.offername,
                        websitename: entry['websitename'],
                        groupedDataChangesfields: [entry]
                    }] : [],
                    localOrAppChangeDescr: '',
                });
            } else {
                const existingEntryIndex = groupedEntries.findIndex(groupedEntry => groupedEntry.principalDomain === entry.principalDomain);

                const changeEntry = {
                    offername: entry.offername,
                    websitename: entry['websitename'],
                    groupedDataChangesfields: [entry]
                } as offerGroup;
                
                entry.dataCategorieId === '1' ? groupedEntries[existingEntryIndex].customerdataChangesfields.push(entry): '';
                entry.dataCategorieId === '2' ? groupedEntries[existingEntryIndex].offerdataChangesfields.push(changeEntry) : '';
                entry.dataCategorieId === '3' ? groupedEntries[existingEntryIndex].localdataChangesfields.push(changeEntry) : '';
            }
        }
        return sort(groupedEntries).asc(groupedEntry => groupedEntry.principalDomain);
    }

    /**
       * gruppiert die Änderungsdaten nach dem Datum
       * @param datas 
       */
    changedFieldObjectToListSortedByDate(datas: ChangedFieldObject[]): ChangesFieldGroupedByDate[] {

        let result: ChangesFieldGroupedByDate[];
        const dates: string[] = [];
        let shortdate = null;
        let tempObjects: ChangedFieldObject[] = null;

        for (const obj of this.containerService.sortByProperty(datas, 'changeDate', 'DESC')) {
            //shortdate = this.stringService.stringToDateshortFormat(obj.changeDate);
            shortdate = obj.changeDate.split(' ')[0];
            if (dates.indexOf(shortdate) < 0)
                dates.push(shortdate);
        }

        let count = 0;
        for (const date of dates) {

            count = count + 1;

            tempObjects = [];

            for (const obj of datas) {

                //shortdate = this.stringService.stringToDateshortFormat(obj.changeDate)
                shortdate = obj.changeDate.split(' ')[0];
                if (shortdate === date)
                    tempObjects.push(obj);
            }


            if (result === null || result === undefined)
                result = [];


            // date oder shortdate  wird es bei firefox (NaN-NaN-NaN wird gezeigt) nicht gehen. 
            // Bezugsdatum aus dem Container auswählen sonst wird es bei firefox (NaN-NaN-NaN wird gezeigt) nicht gehen. 
            //result.push(new ChangesFieldGroupedByDate(tempObjects[0].changeDate.split(" ")[0] , this.containerService.sortByProperty(tempObjects, "changeDate", "DESC")))
            result.push(new ChangesFieldGroupedByDate(date, this.containerService.sortByProperty(tempObjects, 'changeDate', 'DESC')));

        }

        //absteigende Sortierung nach dem Änderungsdatum aller Änderungen
        return result;

    }

    /**
     * sukzessive Ablehnungen speichern
     * @param items  container mit den Ablehnungsobjekten 
     */
    refuseChanges(items: ChangedFieldObject[]) {

        const queries: any = [];

        for (const item of items) {
            if (item.id !== null && item.id !== undefined)
                queries.push({
                    'updateState': this.sqlService.getProcessedSqlQuery(item, userDataChangesState.refused)
                });


        }

        //send sql queries
        return this._http.post<any>(this.apiurl + 'data/common/insertOrUpdate.php', JSON.stringify(queries));

    }

    /**
    * einzige Ablehnung speichern
    * @param item  einzige Ablehnungsobjekt
    */
    refuseChange(item: ChangedFieldObject) {

        return this.refuseChanges([item]);
    }

    /**
     * Ablehnung speichern
     * @param sqlQuery  Sql Update statement
     */
    refuseChangeByQuery(sqlQuery: string) {

        //send sql queries
        return this._http.post<any>(this.apiurl + 'data/common/insertOrUpdate.php', JSON.stringify([{
            'updateState': sqlQuery
        }]));

    }

    /**
     * Ablehnung speichern
     * @param sqlQuery  Sql Update statement
     */
    updateDataChange(oldOffername: string, newOffername: string, principalDomain: string) {

        return this._http.post<any>(this.apiurl + 'data/userDataChanges/updateUserDataChanges.php', {
            'oldoffername': oldOffername,
            'newoffername': newOffername,
            'principalDomain': principalDomain
        });

    }
 
    /**
     * genehmigung einer Änderung speichern
     * @param item Änderungsobjekt
     */
    acceptChanges(items: ChangedFieldObject[]) {

        let list = null;
        let json = null;

        for (const item of items) {
            // Spalte "offername" darf nicht geändert werden, da sie ein Teil der Schlüssel ist
            // alle Änderungen ausser Angebotsname dürfen aktualisiert werden
            if (item.fieldname !== null && item.fieldname !== undefined && item.fieldname !== 'offername') {
                json = this.getJsonObjects(item);
                if (json !== null) {
                    if (list === null)
                        list = [];
                    list.push(json);
                }
            }
        }
        if (list !== null && list.length > 0)

            // erstes Element genügt um die Datenkategorie zu ermitteln
            if (items[0].dataCategorieId === userDataChangesdataCategorieIds.compagnydata || items[0].dataCategorieId === userDataChangesdataCategorieIds.offerdata) {
                //send sql queries
                return this._http.post<any>(this.apiurl + 'data/common/insertOrUpdate.php', JSON.stringify(list));
            } else if (items[0].dataCategorieId === userDataChangesdataCategorieIds.localchange) {

                //send sql queries
                return this._http.post<any>(this.apiurl + 'data/locallist/insertOrDeleteLocal.php', JSON.stringify(list));
            }

    }


    /**
     * genehmigung einer Änderung speichern
     * @param item Änderungsobjekt
     */
    private getJsonObjects(item: ChangedFieldObject): any {


        if (item.dataCategorieId === userDataChangesdataCategorieIds.compagnydata) {
            //send sql queries
            return {
                'insert': this.sqlService.getInsertSqlQueryOfCustomerData(item),
                'update': this.sqlService.getUpdateSqlQueryOfCustomerData(item),
                'updateState': this.sqlService.getProcessedSqlQuery(item, userDataChangesState.accepted),
                'tablename': item.tablename,
                'fieldidentValue': item.fieldidentValue,
                'principalDomain': item.principalDomain

            };
        } else if (item.dataCategorieId === userDataChangesdataCategorieIds.localchange) {

            //send sql queries
            if (item.newvalue) {

                return {
                    'insert': this.sqlService.getInsertSqlQueryOfLocalistData(item),
                    'updateState': this.sqlService.getProcessedSqlQuery(item, userDataChangesState.accepted),
                    'delete': ''

                };

            }
            else if (!item.newvalue)

                return {
                    'delete': this.sqlService.getDeleteSqlQueryOfLocalistData(item),
                    'updateState': this.sqlService.getProcessedSqlQuery(item, userDataChangesState.accepted),
                    'insert': ''


                };
        }
        else if (item.dataCategorieId === userDataChangesdataCategorieIds.offerdata) {
            if (item.offername === null || item.offername === undefined || (item.offername !== null && item.offername !== undefined && item.offername.length === 0)) {
                this.genericDisplayService.showAlert(outputmessage.headererrormodal, outputmessage.offernameEmpty);
                return null;
            }
            //send sql queries
            return {
                'insert': this.sqlService.getInsertSqlQueryOfOfferData(item),
                'update': this.sqlService.getUpdateSqlQueryOfOfferData(item),
                'updateState': this.sqlService.getProcessedSqlQuery(item, userDataChangesState.accepted),
                'tablename': item.tablename,
                'fieldidentValue': item.fieldidentValue,
                'principalDomain': item.principalDomain,
                'offername': item.offername,
                'updateRecipientInOffer': this.sqlService.getUpdateSqlQueryOfTableOffer(item),
                'updateExportField': this.sqlService.getUpdateExportSqlQueryOfTableOffer(item)

            };
        }

        this.genericDisplayService.showAlert(outputmessage.headererrormodal, outputmessage.unknownDataCategory);
        return null;
    }

}
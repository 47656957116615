<h1 mat-dialog-title mat-dialog-draggable-title class="text-primary" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Kategorie mobiler Angebote
    <button type="button" class="close" (click)="onCancel()" data-dismiss="modal" aria-hidden="true">×</button>
</h1>
<br>
<form [formGroup]="mobiletypeform">
    <table width="100%">
        <!--
        <tr>
            <td width="50%">
                <label class="required" for="mobiletypes"> Kategorie mobiler Angebote </label>
            </td>
        </tr>
        -->
        <tr>
            <td width="50%">
                <select formControlName="mobiletypes" class="custom-select col-sm-10" [(ngModel)]="mobiletype">

                    <option [value]="obj.mobiletypeId" *ngFor="let obj of mobiletypes$ | async"> {{obj.description}}
                    </option>

                </select>
            </td>
        </tr>
        <tr>
            <td></td>
        </tr>
        <tr>
            <td align="right"><br><br><br><br><br>
                <button class="btn btn-outline-primary" type="button" (click)="onCancel()" cdkFocusInitial>
                    Abbrechen</button>&nbsp;&nbsp;&nbsp;&nbsp;
                <button class="btn btn-outline-primary" type="button" (click)="onSubmit()"
                    [disabled]="mobiletype==='0'">
                    Weiter</button>

            </td>
        </tr>
    </table>
</form>
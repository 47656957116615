<h1 mat-dialog-title mat-dialog-draggable-title class="text-primary" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Datensatz löschen
    <button type="button" class="close" (click)="closeModal()" data-dismiss="modal" aria-hidden="true">×</button>
</h1>
<div class="box">
<!--
        <h2>
            <p align="center">
            <span class="title">Datensatz löschen</span>
        </p>
        </h2>
    -->
        <main>
            <br>
            <p align="center">
            <i class="fa fa-question-circle" aria-hidden="true"></i>&nbsp;&nbsp;Möchten Sie dieses Angebot wirklich
            unwiderruflich löschen: </p>
            <br> 
            <p align="center"> <b>{{data.offername}}</b>&nbsp;? </p>
                      
        </main>
        <footer>
            <br> 
                <p align="center">
            <button class="btn btn-danger" (click)="deleteOption()"><i
                    class="fa fa-trash-o fa-lg"></i>&nbsp;Löschen</button>&nbsp;
            <button class="btn btn-outline-primary" (click)="closeModal()">&nbsp;Abbrechen</button>
            </p>
        </footer>
    </div>

<h1 mat-dialog-title mat-dialog-draggable-title class="text-primary" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{title}}
    <button type="button" class="close" (click)="cancelOption()" data-dismiss="modal" aria-hidden="true">×</button>
</h1>
<div class="box">
<!-- geht nun über Mat-dialog-title
    <h2>
        <p align="center">
            <span class="title">{{title}}</span></p>
    </h2>
-->
    <main>
        <br>

        <p align="center">
            <i class="fa fa-exclamation-triangle fa-2x" aria-hidden="true" style="color:red"></i>&nbsp;&nbsp; <span class="text-primary"><b>{{intro}}</b></span>
        </p>
        <b>
            <p align="center" [innerHTML]="message">

            </p>
        </b>

        <br>

        <br>
    </main>
    <footer>
        <p align="center">
            <button class="btn btn-outline-primary" (click)="cancelOption()">&nbsp;Schliessen</button></p>
    </footer>
</div>
<br /><br /><br />
<div align="center" class="signoutcontainer col-xm-5">
<h2>
    <br/>
    <br/>  
  Sind Sie sicher, dass Sie sich abmelden wollen?
  </h2>
  <br/>
  <br/>
  <button class="btn btn-success" *ngIf="authStatus" (click)="onContinue()" data-toggle="tooltip" data-placement="top" title="weiterbleiben"><i class="fa fa-sign-in fa-2x" aria-hidden="true"></i></button>&nbsp;&nbsp;
  <button class="btn btn-danger" *ngIf="authStatus" (click)="onSignOut()" data-toggle="tooltip" data-placement="top" title="sich abmelden"><i class="fa fa-sign-out fa-2x" aria-hidden="true"></i></button>


  
  </div>
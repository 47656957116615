
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DatastoreService } from './dataStore.service';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

/**
 * Der Service spricht das API um die Generierung von Pdfs an
 */

@Injectable()
export class DocumentService {

    private apiurl = environment.apiRootDirectory;

    constructor(private _http: HttpClient, private authService: AuthService, private router: Router, private dataStoreService: DatastoreService) { }


    /**
   * lädt der Vertrag zur Auftragsverarbeitung herunter
   */
    downloadAdditionalAgreementPdf() {

        // URL
        const url = this.apiurl + 'data/documents/loadDocument.php';
        // download Optionen definieren
        const options = { responseType: 'blob' as const };

        return this._http.get(url, options);
    }


    /**
   * generiert den Änderungsantrag
   * @param domain 
   * @param offernames 
   * @param OAoffernameOfprincipalDomainSelected 
   */
    downloadRegistrationChangesContract(domain: string, offernames: string, OAoffernameOfprincipalDomainSelected: string) {
    // Parameter setzen
        const params = new HttpParams()
            .set('domain', domain)
            .set('offernames', offernames)
            .set('OAoffername', OAoffernameOfprincipalDomainSelected);

        // URL
        const url = this.apiurl + 'docGenerator/generateChangeApplicationMixed.php';
    

        // download Optionen definieren
        const options = { responseType: 'blob' as const, params };

        return this._http.get(url, options);
    }

    /**
   * lädt 
   * @param offernames 
   */
    downloadingWrapper(offernames: string[]) {

        // Aufruf des Services zur Prüfung ob das Token noch valide ist
        this.authService.isUserTokenStillValid().subscribe(isTokenValid => {
            // falls Das Token noch valid ist
            if (isTokenValid) {

                this.dataStoreService.setDataInLocalstorage('offernames', offernames.join(','));
                this.router.navigateByUrl('registrationchangescontractdownload');

            }
        });
    }

}
<div *ngIf="linkValidResponse" class="container">
    <io-card *ngIf="linkValidResponse.linkValid" class="card">
        <io-card-header>
            <io-heading level="h2" text="Neues Passwort festlegen"></io-heading>
        </io-card-header>
        <io-card-body>       
            <io-input [required]="true" [value]="newPassword" (valueChanged)="updateValue($event)" id="newPassword" [errorHandlingSettings]="getPasswordErrorHandlingSettings()" label="Neues Passwort" type="password"></io-input>
            <io-input [required]="true" [value]="newPasswordRepeat" (valueChanged)="updateValue($event)" id="newPasswordRepeat" label="Neues Passwort wiederholen" type="password"></io-input>
        </io-card-body>
        <io-card-footer>
            <io-button (click)="setNewPassword()" [disabled]="newPassword !== newPasswordRepeat" appearance="primary">Speichern</io-button>
        </io-card-footer>
    </io-card>
    <io-card *ngIf="!linkValidResponse.linkValid" class="card">
        <io-card-header>
            <io-heading level="h2" text="Link ungültig"></io-heading>
        </io-card-header>
        <io-card-body>
            <span>Der aufgerufene Link ist nicht gültig. Bitte versuchen sie einen neuen Link zu beantragen.</span>
        </io-card-body>
        <io-card-footer>
            <io-button (click)="goToLogin()" appearance="secondary">Zum Login</io-button>
        </io-card-footer>
    </io-card>
</div>
import { Injectable } from '@angular/core';

/**
 * Der Service bietet Sortierungsroutinen an
 */

@Injectable({
    providedIn: 'root'
})
export class ContainerService {
    /**
       * gruppiert die Daten nach einem property key
       * @param data array
       * @param property string
       */
    groupBy(data: any, property: any) {
        return data.reduce((acc: any, obj: any) => {
            const key = obj[property];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        });
    }

    /**
     * 
     * @param datas  sortiert die Daten nach einem key
     * @param propertyname Sortierungsschlüssel
     * @param order absteigend|aufsteigend
     */
    sortByProperty<T>(datas: T[], propertyname: keyof T, order: 'ASC' | 'DESC'): T[] {
        datas.sort((a, b) => {
            if (a[propertyname] < b[propertyname]) {
                return -1;
            }
            if (a[propertyname] > b[propertyname]) {
                return 1;
            }
            return 0;
        });
        if (order === 'DESC')
            return datas.reverse();
        return datas;
    }
}
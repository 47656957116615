import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AppSettingsService } from 'src/app/services/appSetting.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { NotificationType } from '@io/web-components';

@Component({
    selector: 'accountrecovery',
    templateUrl: './accountrecovery.component.html',
    styleUrls: ['./accountrecovery.component.css']
})
export class AccountrecoveryComponent implements OnInit {
    errorMessage: string;
    accountRecoveryForm: UntypedFormGroup;

    constructor(private modaldialog: MatDialogRef<AccountrecoveryComponent>,
        private authService: AuthService,
        private formBuilder: UntypedFormBuilder,
        private dialog: MatDialog,
        private appSettingsService: AppSettingsService) { }

    ngOnInit() {
        this.accountRecoveryForm = this.formBuilder.group({
            username: ['', [Validators.required]]
        });
    }

    onSubmit() {
        const username = this.accountRecoveryForm.get('username').value;

        this.authService.sendResetPasswordRequest(username).subscribe((response) => {
            if (response && response.success && response.success === true) {
                this.cancelOption();
                this.showModalDialog(true);
            }
            else {
                this.showModalDialog(false);
            }
        }, (error) => {
            if (error)
                if (error.error && error.error.error) { this.errorMessage = error.error.error; }
                else if (error.message) { this.errorMessage = error.message; }
                else { this.errorMessage = error; }
        });
    }

    cancelOption() {
        this.modaldialog.close();
    }

    showModalDialog(operationWasSuccessfull: boolean) {

        if (operationWasSuccessfull) {
            const event = new CustomEvent('io.sendNotification',
                {
                    detail: [
                        {
                            icon: 'check',
                            type: NotificationType.positive,
                            title: 'Erfolg',
                            text: 'Es wurde eine E-Mail mit einem Link zum Zurücksetzen des Passworts an die angegebene E-Mail-Adresse gesendet.',
                            duration: 8000,
                            dismissable: true
                        }
                    ]
                });
            document.dispatchEvent(event);
        }
        else {
            const event = new CustomEvent('io.sendNotification',
                {
                    detail: [
                        {
                            icon: 'error',
                            type: NotificationType.negative,
                            title: 'Fehler',
                            text: 'Ihre Anfrage konnte nicht bearbeitet werden. Bitte versuchen Sie es später erneut.',
                            duration: 8000,
                            dismissable: true
                        }
                    ]
                });
            document.dispatchEvent(event);
        }
    }
}

import { Component } from '@angular/core';

@Component({
    selector: 'pagenotfound',
    templateUrl: './pagenotfound.component.html',
    styleUrls: ['./pagenotfound.component.css']
})
export class PagenotfoundComponent {

}

import { StringFunctionsService } from 'src/app/services/stringFunctions.service';
import { AppSettingsService } from 'src/app/services/appSetting.service';
import { DatastoreService } from 'src/app/services/dataStore.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MarketerService } from 'src/app/services/marketer.service';
import { DocumentService } from 'src/app/services/document.service';
import { CustomerService } from '../../services/customer.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { IMemberAccount } from 'src/app/models/IMemberAccount';
import { TokenService } from 'src/app/services/token.service';
import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import 'rxjs';


@UntilDestroy()
@Component({
    selector: 'header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
    principaldomain: string;
    licencereceiver: string;

    isLogged$: Observable<boolean>;
    hasOA$: Observable<boolean>;
    connectedUser$: Observable<string>;
    domains$: Observable<string[]>;
    marketerGroupForm$: Observable<string[]>;
    principalDomainIsMarketer: Observable<boolean>;

    availableDomains: string[];

    // dropdown mit den Kürzeln
    sitesForm: UntypedFormGroup;
    // VG Dropdown mit den Lizenznehmern
    marketerGroupForm: UntypedFormGroup;
    // gib an ob der User Admin ist
    isAdmin$: Observable<boolean>;
    // gib an ob ein User ein Vermarkter ist
    isMarketer$: Observable<boolean>;
    // gib an ob der eingeloggter User ein Vermarkter ist
    principalDomainIsMarketer$: Observable<boolean>;
    // Empfänger Email-adresse
    recipientEmailAddress: string;
    // in Frontend angezeigter Tooltip mit accountype + Email
    usertooltip$: Observable<string>;
    // gib an ob der User ein Werbungstraeger ist
    isAdvertisingprovider$: Observable<boolean>;
    // handelt die Anzeige des VG Dropdowns
    showMarketerDropdown = new BehaviorSubject<boolean>(false);
    // speichert die Infos aus dem Child-control
    connectedAccountIsAdmin = new BehaviorSubject<boolean>(false);
    // speichert die Infos aus dem Child-control
    connectedAccountIsMarketer = new BehaviorSubject<boolean>(false);
 
    /**
   * 
   * @param fb 
   * @param authService 
   * @param tokenService 
   * @param stringService 
   * @param router 
   * @param customerService 
   * @param appSettingsService 
   * @param marketerService 
   */
    constructor(
        private fb: UntypedFormBuilder,
        public authService: AuthService,
        public tokenService: TokenService,
        private stringService: StringFunctionsService,
        private router: Router,
        private customerService: CustomerService,
        private appSettingsService: AppSettingsService,
        private marketerService: MarketerService,
        private documentservice: DocumentService,
        private dataStoreService: DatastoreService,
    ) {}

    ngOnInit() {
        // initialisierung, ob ein User eingeloggt ist
        this.isAdmin$ = this.authService.isAdminUser;
        // Initialisierung, ob der eingeloggte User ein vermarkter ist
        this.isMarketer$ = this.authService.isMarkterUser;
        //Tool tip
        this.usertooltip$ = this.authService.Usertooltip;
        // Werbungsträger
        this.isAdvertisingprovider$ = this.authService.isUserAAdvertisingprovider;
        // gib an, ob ein User ein vermarkter ist
        this.principalDomainIsMarketer = this.authService.memberIsMarketer;
        // initialisierung, ob ein User eingeloggt ist
        this.isLogged$ = this.authService.isUserLoggedIn;
        // initialisierung, ob ein User eingeloggt ist
        this.hasOA$ = this.authService.hasUserOA;
        // Ermittlung des Konto-ids des eingeloggten Users
        this.connectedUser$ = this.authService.loggedUser;
        // Ermittlung der Hauptangebote, die vom diesem User verwaltet werden
        this.domains$ = this.customerService.principals;
        // Ermittlung des Vermarkter-Dropdowns
        this.marketerGroupForm$ = this.marketerService.memberOfMarketerGroup;
        // Initialisierung des Kunden-Select-Controls
        this.sitesForm = this.fb.group({
            domainsControl: []
        });

        // Initialisierung des Vermarkter-Select-Controls
        this.marketerGroupForm = this.fb.group({
            marketerGroupControl: []
        });

        //Festlegen des Initialvertes  mit dem userlogin oder  ersten Eintrag   
        if (this.domains$ !== undefined) {
            this.connectedUser$.pipe(untilDestroyed(this)).subscribe(username => {
                const user: any = JSON.parse(this.tokenService.getLoggedInUser());

                let principalDomainSelected: string = user && user.principalDomain ? user.principalDomain : username;

                this.domains$.pipe(untilDestroyed(this)).subscribe(values => {

                    this.availableDomains = values;

                    if (user && user.isAdmin) {
                        principalDomainSelected = this.dataStoreService.getDataFromLocalstorage('principalDomainSelected');
                    }    

                    if (values != null && values.length > 0)
                    // falls der Mitgliedskürzel im dropdown vorhanden ist
                        if (values.indexOf(principalDomainSelected) >= 0) {
                            this.sitesForm.get('domainsControl').setValue(principalDomainSelected);
                        }//Member im dropdown NICHT vorhanden. Trifft generell bei admin zu
                        else {
                            this.sitesForm.get('domainsControl').setValue(values[0]);
                            principalDomainSelected = values[0];
                        }

                    // im Localstorage für weitere Prozesse setzen          
                    this.dataStoreService.setDataInLocalstorage('principalDomainSelected', principalDomainSelected);

                    this.principaldomain = principalDomainSelected;
                    // da der Dropdown nun einen Wert hat, sofort setzen ob
                    //  webkatolog angezeigt werden muss oder nicht
                    this.authService.setIfMemberIsAdvertisingprovider(this.principaldomain);
                });
            });
        }

        //Festlegen des Initialvertes des Vermarkter-Dropdowns  mit dem ersten Eintrag   
        if (this.marketerGroupForm$ !== undefined) {

            this.connectedUser$.pipe(untilDestroyed(this)).subscribe(username => {
                this.marketerGroupForm$.subscribe(values => {
                    if (values != null && values.length > 0)
                        if (values.indexOf(username) >= 0) {
                            this.marketerGroupForm.get('marketerGroupControl').setValue(username);
                        } else {
                            this.marketerGroupForm.get('marketerGroupControl').setValue(values[0]);
                        }
                });
            });//des Initialvertes des Vermarkter-Dropdowns
        }

        // Anzeigen des VG-Dropdowns handeln
        this.checkshowingVgDropdown();

    // Anzeigen des Member-Dropdowns handeln
    }


    /**
   * 
   * @param account 
   */
    getIMemberAccount(account: IMemberAccount) {
        this.connectedAccountIsAdmin.next(account.isAdmin);
        this.connectedAccountIsMarketer.next(account.isMarketer);    
    }

    /**
   * prüft ob, das Dropdown angezeigt werden soll
   */
    checkshowingVgDropdown() {
        this.principalDomainIsMarketer.pipe(untilDestroyed(this)).subscribe(principalDomainIsMarketer => {

            if (principalDomainIsMarketer) {
                this.showMarketerDropdown.next(principalDomainIsMarketer && this.connectedAccountIsAdmin.value);

            } else {
                // ist kein Vermaarkter, also vg-dropdown ausblenden
                this.showMarketerDropdown.next(principalDomainIsMarketer);
            }

        });
    }


    /**
   * befüllt Dropdown mit den Usern
   */
    showOfferList() {
        if ((!this.stringService.isUndefinedNullOrEmpty(this.principaldomain)) && (!this.stringService.isUndefinedNullOrEmpty(this.dataStoreService.getDataFromLocalstorage('principalDomainSelected'))))
            if (this.principaldomain !== this.dataStoreService.getDataFromLocalstorage('principalDomainSelected'))
                this.dataStoreService.setDataInLocalstorage('principalDomainSelected', this.principaldomain);

        //aktualisiert die Anzeige der Angebote
        this.router.navigateByUrl('blank').then(() => {
            this.router.navigate(['offerlist', this.principaldomain]);
        });

    }

    removeDataFromStorage() {
        localStorage.removeItem('compagnydata');
        localStorage.removeItem('contactsOfCompagny');
        localStorage.removeItem('OnlineAngebotOfprincipalDomainSelected');
        localStorage.removeItem('deletion');
        localStorage.removeItem('selectedOffersToPdf');
    }

    onSelectionOfPrincipalChanged(event: Event & { detail?: { label: string, value: string } }) {
    // Aktualisierung des selektierten Members

        this.principaldomain = event.detail.value;
        
        this.dataStoreService.setDataInLocalstorage('principalDomainSelected', this.principaldomain);
        this.removeDataFromStorage();

        //Dropdown des Vermarkters befüllen
        this.marketerService.loadmemberOfVGGroup(this.principaldomain).pipe(untilDestroyed(this)).subscribe(reponse => {

            if (reponse !== null && reponse !== undefined && reponse.length > 0) {
                // Werte zur Interpolation bereitstellen
                this.marketerGroupForm$ = this.marketerService.loadmemberOfVGGroup(this.principaldomain);
                // Mitgliedskürzel als default werte setzen
                this.marketerGroupForm$.pipe(untilDestroyed(this)).subscribe(val => {
                    if (val != null && val.length > 0 && val.indexOf(this.principaldomain) >= 0) {
                        this.marketerGroupForm.get('marketerGroupControl').setValue(this.principaldomain);
                    }
                });
                // True in Observable setzen damit das dropwdown im Frontend sichtbar ist
                this.authService.setIfMemberIsMarketer(true);
                //Ansicht aktualisieren
                this.router.navigateByUrl('blank').then(() => {
                    this.router.navigate(['offerlist', this.principaldomain]);
                });
            }
            else {
                // false damit das dropwdown im Frontend nicht mehr sichtbar ist
                this.authService.setIfMemberIsMarketer(false);
            }
        });

        this.authService.setIfMemberIsAdvertisingprovider(this.principaldomain);
        this.checkshowingVgDropdown();

        if (window.location.href.includes('offerlist/')) {
            this.showOfferList();
        } else {
            setTimeout(() => {
                window.location.reload();
            }, 250);
        }
    }

    onSelectionOfOfferFromMarketerGroupChanged() {
        this.dataStoreService.setDataInLocalstorage('marketerGroup', this.licencereceiver);
        this.sitesForm.get('domainsControl').setValue(this.licencereceiver);
        this.dataStoreService.setDataInLocalstorage('principalDomainSelected', this.licencereceiver);
        this.removeDataFromStorage();
        this.showOfferList();
    }

    sendEmail() {
        let mailto: string;
        let mailSubject: string;
        let mailIntro = '';
        this.appSettingsService.getJSONConfig().subscribe(data => {
            if (data && data.configuration) {
                if (data.configuration.emailRecipients && data.configuration.emailRecipients.support) {
                    mailto = data.configuration.emailRecipients.support;
                }

                if (data.configuration.emailSubjects && data.configuration.emailSubjects.toContactOewa) {
                    mailSubject = data.configuration.emailSubjects.toContactOewa.toString().replace('[[username]]', this.dataStoreService.getDataFromLocalstorage('principalDomainSelected'));
                }
                if (data.configuration.emailBodyIntros && data.configuration.emailBodyIntros.toContactOewa) {
                    mailIntro = data.configuration.emailBodyIntros.toContactOewa.toString().replace('[[username]]', this.dataStoreService.getDataFromLocalstorage('principalDomainSelected'));
                }

                //Outlook starten 
                window.location.href = 'mailto:' + mailto + '?subject=' + mailSubject + '&body=' + mailIntro;
            }
        });
    }

    signOut() {
        this.authService.setIfMemberIsMarketer(false);
        this.showMarketerDropdown.next(false);
        this.authService.signOutUser();
        location.reload();
    }

    changesRegistrationDownloading() {
        this.documentservice.downloadingWrapper([]);
    }

    goToAdmininputData() {
        this.showMarketerDropdown.next(false);
        this.router.navigateByUrl('blank').then(() => {
            this.router.navigate(['admininputdata']);
        });
    }
}


import { ValidationwarningsComponent } from '../GUI-components/modaldialogs/validationwarnings/validationwarnings.component';
import { ValidatorPatternService } from './validatorPattern.service';
import { StringFunctionsService } from './stringFunctions.service';
import outputmessage from 'src/assets/messagetokens.json';
import { MatDialog } from '@angular/material/dialog';
import { IWarning } from '../models/IWarning.model';
import config from 'src/assets/config.json';
import { UntypedFormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import { DraggableModalComponent } from '../GUI-components/modaldialogs/draggable-modal/draggable-modal.component';

@Injectable({
    providedIn: 'root'
})
export class ValidationWarningService {

    constructor(private dialog: MatDialog,
        private stringService: StringFunctionsService,
        private validatorPatternService: ValidatorPatternService
    ) { }

    /**
     * zeigt die Fehlermeldung bei der Passwort-Ändern-Funktion
     * @param passChangeForm Formular der Passwort-Ändern-Funktion
     */
    getPasswordchangeFormWarning(passChangeForm: UntypedFormGroup) {
        let invalidFields: string[] = null;
        const oldpassword = passChangeForm.get('oldpassword').value;
        const newpassword = passChangeForm.get('newpassword').value;
        const newpasswordrepeated = passChangeForm.get('newpasswordrepeated').value;

        if (oldpassword && newpassword && newpasswordrepeated) {

            invalidFields = this.getWarning(passChangeForm, {
                'oldpassword': outputmessage.oldpassword,
                'newpassword': outputmessage.newpassword,
                'newpasswordrepeated': outputmessage.newpasswordrepeated
            });

            if (invalidFields === null)
                invalidFields = [];

            //Min und max Länge prüfen
            if (newpassword.length < this.validatorPatternService.getPasswordMinLength)
                invalidFields.push(outputmessage.newpasswordTooshort);
            if (newpasswordrepeated.length < this.validatorPatternService.getPasswordMinLength)
                invalidFields.push(outputmessage.newpasswordrepeatedTooshort);
            if (newpassword.length > this.validatorPatternService.getPasswordMaxLength)
                invalidFields.push(outputmessage.newpasswordToolong);
            if (newpasswordrepeated.length > this.validatorPatternService.getPasswordMaxLength)
                invalidFields.push(outputmessage.newpasswordrepeatedToolong);
        }

        return invalidFields;
    }

    /**
     * sammelt die Fehlermeldungen auf dem Formular der Allgemeinen Informationen
     * @param customerForm Formular der Allgemeinen Informationen
     */
    getCustomerFormWarning(customerForm: UntypedFormGroup): any {
        const customerfieldsDictionary: any = {
            'customername': outputmessage.customername,
            'city': outputmessage.city,
            'street': outputmessage.street,
            'zipcode': outputmessage.zipcode,
            'orga_contactname': outputmessage.orga_contactname,
            'orga_contactlastname': outputmessage.orga_contactlastname,
            'orga_contactemail': outputmessage.orga_contactemail,
            'orga_contactphone': outputmessage.orga_contactphone,
            'tech_contactname': outputmessage.tech_contactname,
            'tech_contactlastname': outputmessage.tech_contactlastname,
            'tech_contactemail': outputmessage.tech_contactemail,
            'tech_contactphone': outputmessage.tech_contactphone,
            'pruef_contactname': outputmessage.pruef_contactname,
            'pruef_contactlastname': outputmessage.pruef_contactlastname,
            'pruef_contactemail': outputmessage.pruef_contactemail,
            'pruef_contactphone': outputmessage.pruef_contactphone,
        };

        return this.getWarning(customerForm, customerfieldsDictionary);

    }

    /**
     * sammelt die Fehlermeldungen auf dem Formular der Localiste
     * @param value Local
     * @param requiredPattern regex
     */
    getLocallistFormWarning(value: string, requiredPattern: string) {
        let invalidCharacters: string[] = null;

        if (value !== null && value !== undefined) {

            for (let i = 0; i < value.length; i++) {
                //Falls das Zeichen den regex nicht entspricht
                if (this.notMatchRegex(value, requiredPattern)) {

                    if (invalidCharacters === null)
                        invalidCharacters = [];
                    // ungültiges Zeichen merken
                    invalidCharacters.push(value.charAt(i));
                }
            }
            if (invalidCharacters) {
                return this.stringService.FormatString('{0}, {1} ({2})', [outputmessage.localDescription, invalidCharacters.length > 1 ? outputmessage.manyInvalidCharacters : outputmessage.singleInvalidCharacter, invalidCharacters.join(', ')]);
            }
        }
        return null;
    }

    /**
     * sammelt die Fehlermeldungen auf dem Formular der Angebotsdaten
     * @param offerForm Formular der Angebotsdaten
     */
    getOfferFormWarning(offerForm: UntypedFormGroup) {

        const offerdatafieldsDictionary: any = {
            'offername': outputmessage.offername,
            'url': outputmessage.url,
            'pricelist': outputmessage.pricelist,
            'reportingofficerFirstname': outputmessage.reportingofficerFirstname,
            'reportingofficerLastname': outputmessage.reportingofficerLastname,
            'reportingofficerEmail': outputmessage.reportingofficerEmail,
            'reportingofficerPhone': outputmessage.reportingofficerPhone,
            'reportingreceiverEmailOne': outputmessage.reportingreceiverEmailOne,
            'reportingreceiverEmailTwo': outputmessage.reportingreceiverEmailTwo,
            'reportingreceiverEmailThree': outputmessage.reportingreceiverEmailThree,
            'ftpScp': outputmessage.ftpScp,
            'ftpserver': outputmessage.ftpserver,
            'ftpport': outputmessage.ftpport,
            'ftpdirectory': outputmessage.ftpdirectory,
            'ftpuser': outputmessage.ftpuser,
            'ftppassword': outputmessage.ftppassword,
            'newlocallisteItem': outputmessage.localDescription
        };
        return this.getWarning(offerForm, offerdatafieldsDictionary);
    }

    /**
     * gib an, ob einen Wert ein regex einhält
     * @param value 
     * @param regex 
     */
    notMatchRegex(value: string, regex: string): boolean {
        const regexobj = new RegExp(regex);
        return regexobj.exec(value) === null;
    }

    /**
     * lies der Min Wert in einem Regex
     * @param regex 
     */
    private retrieveMinLengthOfRegex(regex: string) {
        const result: any = {};
        let minMaxRegex = '';
        let leftcurlybracketfound = false;
        let rightleftcurlybracketfound = false;

        // regex durchgehen und Regex-Teil der zeichenlänge extrahieren
        for (let i = 0; i < regex.length; i++) {
            if (regex.charAt(i) === '{')
                leftcurlybracketfound = true;
            if (leftcurlybracketfound)
                minMaxRegex = minMaxRegex + regex.charAt(i);
            if (regex.charAt(i) === '}')
                rightleftcurlybracketfound = true;
        }
        //Extrahierte Zeichen splitten
        if (leftcurlybracketfound && rightleftcurlybracketfound) {
            // Min lesen
            result['min'] = minMaxRegex.split(',')[0].split('{')[1];
            //Max lesen
            result['max'] = minMaxRegex.split(',')[1].split('}')[0];
        }
        return result;
    }

    /**
     * liefert die Zeichen, die nach dem Regex nicht gelten
     * @param value Zeichen die geprüft werden müssen 
     * @param regex Regulärer Ausdruck
     */
    getInvalidCharOfStringByRegex(value: string, regex: RegExp): string[] {

        // Container zur Speicherung der invalid Zeichen
        let invalidCharactersResult: string[] = null;
        let match = null;
        for (let i = 0; i < value.length; i++) {
            // Zeichen mit dem Regex prüfen, ob es match
            match = regex.exec(value.charAt(i));

            // Das zeichen mtach nicht den regex
            if (match === null || (match !== null && match[0] !== null && match[0].length === 0)) {

                //Init des Containers zur Speicherung der invalid Zeichen
                if (invalidCharactersResult === null)
                    invalidCharactersResult = [];

                if (invalidCharactersResult.indexOf(value.charAt(i)) < 0)
                    // falls Das zeichen das Leerzeichen ist
                    if (value.charAt(i) === ' ') {
                        if ((invalidCharactersResult.indexOf(outputmessage.spaceCharacter) < 0))
                            invalidCharactersResult.push(outputmessage.spaceCharacter);
                    }
                    else
                        invalidCharactersResult.push(value.charAt(i));
            }
        }

        return invalidCharactersResult;
    }

    /**
     * Sonderprüfung bei den EMails
     * 
     * @param controlId Steuerelement Id
     * @param controlValue wert des Steuerlelements
     * @returns 
     */
    private specialEmailCheck(controlId: string, controlValue: string): any {
        let isEmailInvalid = false;
        let specialmessage: string = null;

        // Falls das Steuerelement ein Feld für EMail ist
        if (['orga_contactemail', 'tech_contactemail', 'pruef_contactemail', 'reportingofficerEmail', 'reportingreceiverEmailOne', 'reportingreceiverEmailTwo', 'reportingreceiverEmailThree'].indexOf(controlId) >= 0) {
            // EMail-Domain, dot und Land (also nach @)
            let partAfterAt = null;
            // Regex definieren
            const rgxWithCountry = new RegExp('^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,5}$');

            // Email splitten
            const emailparts = controlValue.split('@');

            // Falls 2 Teile vorliegen
            if (emailparts.length === 2)
                // Teile nach dem @ splitten
                partAfterAt = emailparts[1].split('.');

            let coutrystringLength = 0;
            // Zeichen nach dem letzte dot (also punkt bevor Landkürzel)
            let stringAfterLastdot: string = null;
            // Landkürzel auswerten
            if (partAfterAt !== null && partAfterAt.length > 1) {
                stringAfterLastdot = partAfterAt[partAfterAt.length - 1];
                coutrystringLength = stringAfterLastdot.length;
            }

            // keine User-Angabe
            const emailFieldIsEmpty = controlValue.length === 0;
            // User-Angabe ohne Landkürzel
            const emailWithoutCountryExt = null === stringAfterLastdot || coutrystringLength === 0;
            // User-Angabe mit fehlerhaftem Landkürzel
            const emailGivenWrongCountryextension = coutrystringLength < config.configuration.emailCountryCode.min || coutrystringLength > config.configuration.emailCountryCode.max;
            // Initialisierung
            isEmailInvalid = (rgxWithCountry.test(controlValue) && ((emailWithoutCountryExt) || (emailGivenWrongCountryextension)));

            // Pflichtsfelder-EMail handeln
            /* auskommentiert regex 2.0 am 20.10.2021  Prüfbericht Ansprechpartner bzw. 'pruef_contactemail' ist nicht mehr als Pflichtfeld zuhandeln*/
            //if (['orga_contactemail', 'tech_contactemail', 'pruef_contactemail'].indexOf(controlId) >= 0) {
            if (['orga_contactemail', 'tech_contactemail'].indexOf(controlId) >= 0)
                isEmailInvalid = isEmailInvalid || emailFieldIsEmpty || (!rgxWithCountry.test(controlValue));

            // NICHT Pflichtsfelder-EMail handeln
            else
                // Initialisierung der Value greift wenn User Angabe vorliegt
                isEmailInvalid = emailFieldIsEmpty === false && ((!rgxWithCountry.test(controlValue)) || isEmailInvalid);

            // detaillierter E-Mailadresse-Fehler festlegen
            if (isEmailInvalid) {

                if (emailWithoutCountryExt)
                    specialmessage = this.getSpecialMessage(partAfterAt, stringAfterLastdot);
                if (emailGivenWrongCountryextension)
                    specialmessage = this.getSpecialMessage(partAfterAt, stringAfterLastdot);
            }
        }
        return { 'isEmailInvalid': isEmailInvalid, 'specialmessage': specialmessage };
    }

    /**
     * ermittel eine bestimmte Warnung um den Fehler über die Email-Adresse
     * @param partAfterAt EMail-Domäne
     * @param countryCode Landkürzel
     */
    private getSpecialMessage(partAfterAt: string, countryCode: string): string {
        let specialmessage: string = null;

        if (partAfterAt === null)
            specialmessage = outputmessage.specialMessageByNoDomain;
        // nach dem @ gibt kein '.'"
        else if (countryCode === null)
            specialmessage = outputmessage.specialmessageByEmailNoCountryCode;
        // nach dem @ gibt kein '.'
        else if (partAfterAt.length === 1 || countryCode.length === 0)
            specialmessage = outputmessage.specialmessageByEmailNoCountryCode;
        // Landkürzel hat weniger als config.configuration.emailCountryCode.min Zeichen
        else if (countryCode.length < config.configuration.emailCountryCode.min)
            specialmessage = outputmessage.specialmessageByEmailshortCountryCode;
        // Landkürzel hat mehr als config.configuration.emailCountryCode.max Zeichen
        else if (countryCode.length > config.configuration.emailCountryCode.max)
            specialmessage = outputmessage.specialmessageByEmaillongCountryCode;


        return specialmessage;
    }

    /**
     * sammelt die Datenfehler pro Steuerlement
     * 
     * @param form Formular
     * @param controlId Steuerlement Id 
     */
    getWarningByControld(form: UntypedFormGroup, controlId: string): IWarning {

        let value: string = null;
        const result: IWarning = {
            invalidCharacterFound: false,
            requiredFieldEmptyFound: false,
            invalidEmailaddressFound: false,
            stringLengthNotCorrect: false
        };

        for (const name in form.controls) {
            if (name === controlId) {

                value = this.stringService.trimmed(form.controls[name].value);

                result.invalidEmailaddressFound = this.specialEmailCheck(controlId, value).isEmailInvalid;

                if (form.controls[name].errors) {

                    if (form.controls[name].errors.pattern && form.controls[name].errors.pattern.requiredPattern) {
                        // entspricht NICHT der Regex
                        if (this.notMatchRegex(form.controls[name].value, form.controls[name].errors.pattern.requiredPattern)) {

                            const chars = this.getInvalidCharOfStringByRegex(value,
                                new RegExp(form.controls[name].errors.pattern.requiredPattern));
                            result.invalidCharacterFound = chars !== null && chars.length > 0;

                            if (!this.stringMinLengthMatchRegex(value,
                                form.controls[name].errors.pattern.requiredPattern) ||
                                !this.stringMaxLengthMatchRegex(value,
                                    form.controls[name].errors.pattern.requiredPattern))
                                result.stringLengthNotCorrect = true;
                        }
                    }
                    // Pflichtfeld
                    else if (form.controls[name].errors.required) {
                        result.requiredFieldEmptyFound = true;
                    } //falls EMail invalid 
                    else if (form.controls[name].errors.email) {
                        result.invalidEmailaddressFound = true;
                    }
                }
            }
        }
        return result;
    }

    /**
     * gib an, ob die Min-Zeichenlänge die NICHT-Übereinstimmung verursacht hat
     * @param value der Wert
     * @param regex der Regulärausdruck
     */
    minLengthRegex(regex: string): any {
        let result = undefined;
        // Falls kein invalides Zeichen vorliegt, bitte nach den Zeichenlängen prüfen 
        const minmaxlength = this.retrieveMinLengthOfRegex(regex);
        if (minmaxlength && minmaxlength.min)
            result = minmaxlength.min;
        return result;
    }

    /**
     * gib an, ob die Min-Zeichenlänge die NICHT-Übereinstimmung verursacht hat
     * @param value der Wert
     * @param regex der Regulärausdruck
     */
    stringMinLengthMatchRegex(value: string, regex: string): boolean {
        // Falls kein invalides Zeichen vorliegt, bitte nach den Zeichenlängen prüfen 
        const minmaxlength = this.retrieveMinLengthOfRegex(regex);
        if (minmaxlength && minmaxlength.min)
            if (value)
                return !(value.length < minmaxlength.min);
        return true;
    }

    /**
     * gib an, ob die Max-Zeichenlänge die NICHT-Übereinstimmung verursacht hat
     * @param value der Wert 
     * @param regex der Regulärausdruck
     */
    stringMaxLengthMatchRegex(value: string, regex: string): boolean {
        // Falls kein invalides Zeichen vorliegt, bitte nach den Zeichenlängen prüfen 
        const minmaxlength = this.retrieveMinLengthOfRegex(regex);
        if (minmaxlength && minmaxlength.max)
            if (value)
                return !(value.length > minmaxlength.max);
        return true;
    }

    /**
     * geht ein Formular durch und prüft ob invaliden Daten vorhanden sind
     * @param form das Formular
     * @param fieldnamesDictionary  zu prüfenden Feldnamen
     */
    private getWarning(form: UntypedFormGroup, fieldnamesDictionary: any) {
        let warningsByField = null;
        let invalidCharacters: string[] = null;
        let minMaxLengthInvalids: string[] = null;
        let errors = null;
        for (const name in form.controls) {
            // Falls das Container nicht  initialisiert ist
            if (warningsByField === null)
                warningsByField = [];

            // Falls Das Formular ist invalid
            if (form.controls[name].invalid) {

                if (warningsByField === null)
                    warningsByField = [];

                // vorhandene Fehler im Formular durchgehen    
                if (form.controls[name].errors) {

                    errors = form.controls[name].errors;
                    //Falls das Feld der regex nicht entspricht
                    if (errors.pattern && errors.pattern.requiredPattern) {
                        const regex = new RegExp(form.controls[name].errors.pattern.requiredPattern);

                        minMaxLengthInvalids = [];

                        // invalid Zeichen sammeln
                        invalidCharacters = this.getInvalidCharOfStringByRegex(form.controls[name].value, regex);

                        // Zeichenlängen
                        // Falls kein invalides Zeichen vorliegt, bitte nach den Zeichenlängen prüfen 
                        const minmaxlength = this.retrieveMinLengthOfRegex(form.controls[name].errors.pattern.requiredPattern);

                        if (!this.stringMinLengthMatchRegex(form.controls[name].value, form.controls[name].errors.pattern.requiredPattern)) {
                            minMaxLengthInvalids.push(outputmessage.tooshort);
                        }
                        else if (!this.stringMaxLengthMatchRegex(form.controls[name].value, form.controls[name].errors.pattern.requiredPattern))
                            //else if (minmaxlength.max && form.controls[name].value.length > minmaxlength.max)
                            minMaxLengthInvalids.push(this.stringService.FormatString(outputmessage.toolong, [form.controls[name].value.length, minmaxlength.max]));

                        // Fall invalides Zeichen vorliegt, Nachricht hinzufügen
                        if (invalidCharacters && invalidCharacters.length > 0)
                            warningsByField.push(this.stringService.FormatString('{0}, {1} (<font color=#ff0000>{2}</font>)', [fieldnamesDictionary[name], invalidCharacters && invalidCharacters.length > 1 ? outputmessage.manyInvalidCharacters : outputmessage.singleInvalidCharacter, invalidCharacters.join(', ')]));
                        // Fall die Zeichenlänge nicht gültig ist, die Nachricht hinzufügen
                        if (minMaxLengthInvalids && minMaxLengthInvalids.length > 0)
                            warningsByField.push(this.stringService.FormatString('{0} (<font color=#ff0000>{1}</font>)', [fieldnamesDictionary[name], minMaxLengthInvalids.join(', ')]));
                    } //falls Feld leer 
                    else if (errors.required) {
                        warningsByField.push(this.stringService.FormatString('{0} <font color=#ff0000>{1}</font> ', [fieldnamesDictionary[name], outputmessage.fieldIsEmpty]));
                    } //falls EMail invalid 
                    else {
                        const result = this.getInvalidCharOfStringByRegex(form.controls[name].value, new RegExp(config.configuration.regex.email));

                        // greift wenn E-Mail-Pattern nicht eingehalten ist @.llk oder .@llkd oder .kin@lks oder Zeichenlänge zu lang
                        if (result === null) {
                            // Max Zeichen von emails
                            const maxlenEmail = this.retrieveMinLengthOfRegex(config.configuration.regex.email).max;
                            // Falls Angabe zu lang
                            if (form.controls[name].value.length > maxlenEmail)
                                warningsByField.push(this.stringService.FormatString('{0} <font color=#ff0000>{1}</font>', [fieldnamesDictionary[name], this.stringService.FormatString(outputmessage.emailTooLong, [maxlenEmail])]));
                            else
                                warningsByField.push(this.stringService.FormatString('{0} <font color=#ff0000>{1}</font>', [fieldnamesDictionary[name], outputmessage.emailSchemaIsInvalid]));

                        }
                        else if (result && result.length >= 1) {
                            warningsByField.push(this.stringService.FormatString('{0} <font color=#ff0000>{1} ({2})</font>', [fieldnamesDictionary[name], outputmessage.entryIsInvalid, result.join(', ')]));
                        }
                        else {
                            // Sondercheck der EMail handeln
                            const specialcheck = this.specialCheckWrapper(name, form.controls[name].value, fieldnamesDictionary);

                            if (specialcheck)
                                warningsByField.push(specialcheck);
                        }
                    }
                }
            }
            else {
                // Sondercheck der EMail aufrufen. greift nur bei den Email-Feldern
                const specialcheck = this.specialCheckWrapper(name, form.controls[name].value, fieldnamesDictionary);
                if (specialcheck) {
                    warningsByField.push(specialcheck);
                }
            }
        }
        return warningsByField;
    }

    /**
     * 
     * @param controlId Steuerelement ID
     * @param controlValue Wert im Steuerelement
     * @param fieldnamesDictionary Mapping der Steuerelemente 
     */
    private specialCheckWrapper(controlId: string, controlValue: string, fieldnamesDictionary: any): string {
        const specialresult = this.specialEmailCheck(controlId, controlValue);

        if (specialresult.isEmailInvalid)
            if (this.stringService.isUndefinedNullOrEmpty(specialresult.specialmessage))
                return this.stringService.FormatString('{0} <font color=#ff0000>{1}</font>', [fieldnamesDictionary[controlId], outputmessage.entryIsInvalid]);
            else
                return this.stringService.FormatString('{0} <font color=#ff0000>{1} ({2})</font>', [fieldnamesDictionary[controlId], outputmessage.entryIsInvalid, specialresult.specialmessage]);

        return null;
    }

    /**
     * Zeigt die Datenfehler an
     * @param invalidFields die Datenfehler
     */
    popupWarning(invalidFields: string[]) {

        let height = '320px';
        let imessage: any;
        //ein kleines Fenster verwenden
        if (invalidFields.length === 1) {

            imessage = {
                title: outputmessage.headerwarningmodal,
                message: invalidFields.join(''),
                length: invalidFields.length

            };
        }//ein breites Fenster verwenden
        else if (invalidFields.length > 1) {

            imessage = {
                title: outputmessage.headerwarningmodal,
                message: invalidFields.join('<br>'),
                length: invalidFields.length

            };
            height = (320 + ((invalidFields.length - 1) * 30)).toString() + 'px';
        }

        let witdh = '550px';
        // Falls eine lange Fehlermeldung vorliegt, Breite des Modals anpassen
        if ((imessage !== null || imessage !== undefined) && imessage.message.indexOf('Landkürzel') >= 0)
            witdh = '700px';

        this.dialog.open(ValidationwarningsComponent, {
            height: height,
            width: witdh,
            data: imessage
        });
    }

    /**
    * Zeigt die Datenfehler an
    * @param invalidFields die Datenfehler
    */
    popupDraggableWarning(invalidFields: string[]) {
        let height = '320px';
        let imessage: any;
        //ein kleines Fenster verwenden
        if (invalidFields.length === 1) {

            imessage = {
                title: outputmessage.headerwarningmodal,
                message: invalidFields.join(''),
                length: invalidFields.length

            };
        } //ein breites Fenster verwenden
        else if (invalidFields.length > 1) {

            imessage = {
                title: outputmessage.headerwarningmodal,
                message: invalidFields.join('<br>'),
                length: invalidFields.length

            };
            height = (320 + ((invalidFields.length - 1) * 30)).toString() + 'px';
        }

        let witdh = '550px';
        if (imessage.message.indexOf('Landkürzel') >= 0)
            witdh = '700px';

        this.dialog.open(DraggableModalComponent, {
            height: height,
            width: witdh,
            data: imessage
        });
    }
}
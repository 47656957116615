import outputmessage from 'src/assets/messagetokens.json';
import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

/**
 * Komponent-Klasse zum Anzeigen von Modal mit Warnungen, dass eine Änderung nicht signifikante ist
 */

@Component({
    selector: 'nosignificantchanges',
    templateUrl: './nosignificantchanges.component.html',
    styleUrls: ['./nosignificantchanges.component.css']
})
export class NosignificantchangesComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<NosignificantchangesComponent>) { }

    message: string;
    ngOnInit() {
        this.message = outputmessage.entryIsInsignificant;
    }


    cancelOption() {
        this.dialogRef.close();
    }
}

<h1 mat-dialog-title mat-dialog-draggable-title class="text-primary" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{data.title}}
    <button type="button" class="close" (click)="cancelOption()" data-dismiss="modal" aria-hidden="true">×</button>
</h1>
<div class="box">
<!--
    <h2>
        <p align="center">
            <span class="title">{{data.title}}</span></p>
    </h2>
-->
    <main>
        <br><br>

        <p align="center" *ngIf="data.screen!=='success'">
            <i *ngIf="data.info===1" class="fa fa-info-circle fa-2x" aria-hidden="true" style="color:#cccc99;"></i>  
            <i *ngIf="data.info!==1" class="fa fa-exclamation-triangle fa-2x" aria-hidden="true" style="color:red"></i>&nbsp;&nbsp;{{data.message}}
        </p>
        <p align="center" *ngIf="data.screen==='success'">
                <i class="fa fa-check-circle" aria-hidden="true" style="color:green"></i>&nbsp;&nbsp;{{data.message}}
           
        </p>
       
        <br>
        <br>
    </main>
    <footer>
        <p align="center">
            <button class="btn btn-outline-primary" (click)="cancelOption()">&nbsp;Schliessen</button></p>
    </footer>
</div>

import { PasswordchangeconfirmationComponent } from './GUI-components/modaldialogs/passwordchangeconfirmation/passwordchangeconfirmation.component';
import { OfferdeletionconfirmationComponent } from './GUI-components/modaldialogs/offerdeletionconfirmation/offerdeletionconfirmation.component';
import { NosignificantchangesComponent } from './GUI-components/modaldialogs/nosignificantchanges/nosignificantchanges.component';
import { GenericmodaldisplayComponent } from './GUI-components/modaldialogs/genericmodaldisplay/genericmodaldisplay.component';
import { ValidationwarningsComponent } from './GUI-components/modaldialogs/validationwarnings/validationwarnings.component';
import { MobiletypeselectorComponent } from './GUI-components/modaldialogs/mobiletypeselector/mobiletypeselector.component';
import { DraggableModalComponent } from './GUI-components/modaldialogs/draggable-modal/draggable-modal.component';
import { AccountrecoveryComponent } from './GUI-components/accountrecovery/accountrecovery.component';
import { PasswordchangeComponent } from './GUI-components/passwordchange/passwordchange.component';
import { ResetPasswordComponent } from './GUI-components/reset-password/reset-password.component';
import { PagenotfoundComponent } from './GUI-components/pagenotfound/pagenotfound.component';
import { LocallistDatacomparatorService } from './services/locallistDatacomparator.service';
import { SessioncountdownComponent } from './sessioncountdown/sessioncountdown.component';
import { PdfcontractComponent } from './GUI-components/pdfcontract/pdfcontract.component';
import { CustomerDatacomparatorService } from './services/customerdataComparator.service';
import { OfferdataComparatorService } from './services/offerdataComparator.service';
import { CanDeactivateGuard } from './shared/can-deactivate/can-deactivate.guard';
import { getGermanPaginatorIntl } from './angularmaterial/german-paginator-intl';
import { ValidationWarningService } from './services/validationWarning.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustommaterialModule } from './angularmaterial/custommaterial.module';
import { SignoutComponent } from './GUI-components/signout/signout.component';
import { ValidatorPatternService } from './services/validatorPattern.service';
import { UserDataChangesService } from './services/userDataChanges.service';
import { StringFunctionsService } from './services/stringFunctions.service';
import { GenericDisplaySservice } from './services/genericDisplay.service';
import { SigninComponent } from './GUI-components/signin/signin.component';
import { HeaderComponent } from './GUI-components/header/header.component';
import { NgxUiLoaderRouterModule, NgxUiLoaderModule } from 'ngx-ui-loader';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DataCategoriesService } from './services/dataCategories.service';
import { DataComparatorService } from './services/dataComparator.service';
import { WebComponentsAngularModule } from '@io/web-components-angular';
import { MobiletypesService } from './services/mobiletypes.service';
import { AppSettingsService } from './services/appSetting.service';
import { ErrorInterceptor } from './interceptors/ErrorInterceptor';
import { WebcatalogService } from './services/webcatalog.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MembernewsService } from './services/membernews.service';
import { LocallistService } from './services/locallist.service';
import { DatastoreService } from './services/dataStore.service';
import { JwtInterceptor } from './interceptors/JwtInterceptor';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomerService } from './services/customer.service';
import { DocumentService } from './services/document.service';
import { SqlQueryService } from './services/sqlQuery.service';
import { MarketerService } from './services/marketer.service';
import { ContactService } from './services/contact.service';
import { ApiCoreService } from './services/apiCore.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { MemberService } from './services/member.service';
import { CryptoService } from './services/crypto.service';
import { AuthGuard } from './services/authguard.service';
import { TokenService } from './services/token.service';
import { OfferService } from './services/offer.service';
import { AppRoutingModule } from './app-routing.module';
import { EmailService } from './services/email.service';
import { AuthService } from './services/auth.service';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { UserService } from './services/user.service';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        SessioncountdownComponent,
        SigninComponent,
        SignoutComponent,
        PagenotfoundComponent,
        PdfcontractComponent,
        NosignificantchangesComponent,
        AccountrecoveryComponent,
        PasswordchangeComponent,
        PasswordchangeconfirmationComponent,
        ValidationwarningsComponent,
        GenericmodaldisplayComponent,
        OfferdeletionconfirmationComponent,
        MobiletypeselectorComponent,
        DraggableModalComponent,
        ResetPasswordComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        BrowserAnimationsModule,
        CustommaterialModule,
        AppRoutingModule,
        FormsModule,
        TooltipModule.forRoot(),
        ReactiveFormsModule,
        HttpClientModule,
        NgxUiLoaderModule,
        PdfViewerModule,
        WebComponentsAngularModule,
        NgxUiLoaderRouterModule.forRoot({ showForeground: true, exclude: ['/datachangeprocessing', '/datachangessortedbydate'] }) // Automatically show loader for router events
    ],
    providers: [
        HttpClientModule,
        AuthGuard,
        CanDeactivateGuard,
        AuthService,
        OfferService,
        CustomerService,
        TokenService,
        AppSettingsService,
        ContactService,
        CustomerDatacomparatorService,
        UserDataChangesService,
        EmailService,
        SqlQueryService,
        StringFunctionsService,
        DocumentService,
        MembernewsService,
        ApiCoreService,
        LocallistService,
        LocallistDatacomparatorService,
        DataCategoriesService,
        ValidatorPatternService,
        ValidationWarningService,
        GenericDisplaySservice,
        OfferdataComparatorService,
        DataComparatorService,
        MarketerService,
        WebcatalogService,
        MemberService,
        MobiletypesService,
        CryptoService,
        DatastoreService,
        UserService,
        {
            provide: MAT_DIALOG_DATA,
            useValue: {} // Add any data you wish to test if it is passed/used correctly
        },
        {
            provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true
        },
        {
            provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true
        },
        { provide: MatPaginatorIntl, useValue: getGermanPaginatorIntl() }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

import { environment } from 'src/environments/environment';
import { Customer } from 'src/app/models/Customer.model';
import { MarketerService } from './marketer.service';
import { HttpClient } from '@angular/common/http';
import { TokenService } from './token.service';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Der Service spricht die API an, und liefert die Firmendaten
 */

@Injectable({
    providedIn: 'root'
})
export class CustomerService {

    private apiurl = environment.apiRootDirectory;
    // Mitgliedskürzel
    private principaldomains = new Subject<string[]>();

    constructor(private _http: HttpClient, private marketerService: MarketerService, private tokenService: TokenService) { }

    /**
   * lies die Kundendaten
   * @param onlyOne, gib an, ob das Laden auf einem einzigen Mitglied sich einschränken soll 
   * @param principalDomain, Mitgliedskürzel 
   */
    readCustomers(onlyOne?: boolean, principalDomain?: string) {
        if (onlyOne !== null && true === onlyOne && principalDomain !== null && principalDomain !== undefined) {
            return this._http.post<Customer[]>(this.apiurl + 'data/customers/readSingle.php', { 'principalDomain': principalDomain });
        }
        return this._http.get<Customer[]>(this.apiurl + 'data/customers/readAll.php');
    }

    /**
   * aktiviert oder deaktiviert ein Mitglied
   * @param principalDomain, Mitgliedskürzel 
   * @param action, die Operation aktivieren|deaktivieren 
   */
    activateOrDeactivate(principalDomain: string, action: string) {
        return this._http.post<any>(this.apiurl + 'data/customers/activateOrDeactivate.php', {
            'principalDomain': principalDomain,
            'action': action
        });
    }

    // lies die Kundendaten
    readDeactivatedCustomers() {
        return this._http.get<any>(this.apiurl + 'data/customers/readDeactivateds.php');
    }

    // liefert das Hauptangebot des Users
    get principals() {
        return this.principaldomains.asObservable();
    }

    // lädt nach dem Accountt-Kontekt alle Users
    public loadprincipals() {
        const user: any = JSON.parse(this.tokenService.getLoggedInUser());

        // Aufgrund der Service-Injection, muss dieses Property neu Initialisiert    
        const domains: string[] = [];

        if (user !== null) {

            // Vermakter User
            if (user.isMarketer) {
                // Lizenznehmer laden
                this.marketerService.loadmemberOfVGGroup(user.principalDomain).subscribe((licenceReceiversList) => {
                    // falls der Vermarkter kein Lizenznehmer hat
                    if (licenceReceiversList !== null && licenceReceiversList !== undefined && licenceReceiversList.length === 0)
                        licenceReceiversList.push(user.principalDomain);
                    for (const member of licenceReceiversList) {
                        if (member !== null && member.length > 0 && 0 > domains.indexOf(member))
                            domains.push(member);
                    }
                    this.principaldomains.next(domains.slice());
                });

            } else {
                this.readCustomers().subscribe((response) => {
                    for (const customer of response['data']) {
                        // Admin user
                        if (user.isAdmin) {
                            if (customer.principalDomain !== null && customer.principalDomain.length > 0 && 0 > domains.indexOf(customer.principalDomain))
                                domains.push(customer.principalDomain);
                        }
                        // other User
                        else {
                            if (customer.loginname === user.username && 0 > domains.indexOf(customer.principalDomain)) {
                                domains.push(customer.principalDomain);
                            }
                        }
                    }

                    this.principaldomains.next(domains.slice());
                });
            }
        }
    }
}

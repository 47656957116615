import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import outputmessage  from 'src/assets/messagetokens.json';
import { Component, OnInit, Inject } from '@angular/core';

/**
 * Komponent-Klasse zum Anzeigen von Modal mit Warnungen, dass die Daten nach dem Regex nicht validen sind 
 */

@Component({
    selector: 'validationwarnings',
    templateUrl: './validationwarnings.component.html',
    styleUrls: ['./validationwarnings.component.css']
})
export class ValidationwarningsComponent implements OnInit {

    title: string; 
    message: string;
    screen: string;
    intro: string;
    constructor(public dialogRef: MatDialogRef<ValidationwarningsComponent>,  @Inject(MAT_DIALOG_DATA) private data: any) { }

    ngOnInit() {  

        this.title = this.data.title;
        this.message = this.data.message;
        this.intro = this.data && this.data.length &&  this.data.length > 1 && outputmessage ? outputmessage.manyInvalidfields : outputmessage.singleInvalidfield;
    }

    cancelOption() {
        this.dialogRef.close();
    }
}

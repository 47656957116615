<br /><br /><br />
<div class="signincontainer col-xl-3">
    <h4 align="center"><br /> Kenntwort ändern<br /><br /></h4>
    <form [formGroup]="passChangeForm" (keydown.enter)="$event.preventDefault()" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label for="email" class="required"> altes Passwort</label>
            <input type="password" id="oldpassword" class="form-control" formControlName="oldpassword">
        </div>
        <div class="form-group">
            <label for="email" class="required"> neues Passwort</label>
            <input type="password" id="newpassword" class="form-control" formControlName="newpassword">
        </div>
        <div class="form-group">

            <label for="password" class="required"> neues Passwort wiederholen</label>
            <input type="password" id="newpasswordrepeated" class="form-control" formControlName="newpasswordrepeated">
        </div>
        <br><br> <br/><br/><br>
        <p align="center">
            <button class="validationwarningbutton" (mouseover)=showValidationwarningsModaldialog(passChangeForm)>
            <button [disabled]="passChangeForm.invalid" class="btn btn-outline-primary" type="submit"><i
                    class="fa fa-save"></i>&nbsp;Speichern</button></button>&nbsp;&nbsp;

            <button class="btn btn-outline-primary" (click)="OnCancel()" type="button" data-toggle="tooltip" data-placement="top"
                title="Abbrechen"> Abbrechen</button>
               
        </p>
    </form>
    <p class="text-danger">{{errorMessage}}</p>

</div>
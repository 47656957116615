import { PasswordchangeconfirmationComponent } from '../modaldialogs/passwordchangeconfirmation/passwordchangeconfirmation.component';
import { ValidationWarningService } from 'src/app/services/validationWarning.service';
import { ValidatorPatternService } from 'src/app/services/validatorPattern.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import outputmessage from 'src/assets/messagetokens.json';
import { IMessage } from 'src/app/models/IMessage.model';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

/**
 * Komponent-Klasse zur Passwortändern-funktion
 */

@Component({
    selector: 'passwordchange',
    templateUrl: './passwordchange.component.html',
    styleUrls: ['./passwordchange.component.css']
})
export class PasswordchangeComponent implements OnInit {
    // Das Formular
    passChangeForm: UntypedFormGroup;
    // der Fehler
    errorMessage: string;

    /**
   * Konstruktor der Klasse
   * @param formBuilder, Formbuilder 
   * @param authService, Service für die Authentisierung 
   * @param router, Navigationshändler 
   * @param dialog, Matdialog 
   * @param appSettingsService, Service für die Konfigurationsdaten 
   * @param validatorPatternService, Service für regex 
   * @param validationWarningService, Service für Warnungen 
   */
    constructor(private formBuilder: UntypedFormBuilder,
        private authService: AuthService,
        private router: Router,
        private dialog: MatDialog,
        private validatorPatternService: ValidatorPatternService,
        private validationWarningService: ValidationWarningService) {
    }

    ngOnInit() {
        /* Initilialiserung des Forms */
        this.passChangeForm = this.formBuilder.group({
            oldpassword: ['', [Validators.required, Validators.pattern(this.validatorPatternService.regexOfPasswordChars)]],
            newpassword: ['', [Validators.required, Validators.pattern(this.validatorPatternService.regexOfPasswordChars)]],
            newpasswordrepeated: ['', [Validators.required, Validators.pattern(this.validatorPatternService.regexOfPasswordChars)]]
        });
    }

    onSubmit() {
        // Höhe des Modalfensters
        const modalheight = '280px';

        // Validitätsprüfung
        const invalidFields = this.validationWarningService.getPasswordchangeFormWarning(this.passChangeForm);

        if (invalidFields && invalidFields.length > 0)
            this.validationWarningService.popupWarning(invalidFields);
        else
            this.authService.isUserTokenStillValid().subscribe(isTokenValid => {
                if (isTokenValid) {
                    // Formular lesen
                    const oldpassword = this.passChangeForm.get('oldpassword').value;
                    const newpassword = this.passChangeForm.get('newpassword').value;
                    const newpasswordrepeated = this.passChangeForm.get('newpasswordrepeated').value;

                    const username = this.authService.usernameLogged;
                    if (newpassword === newpasswordrepeated) {

                        // prüfen, ob die Passwort-Regel erfüllt ist 
                        if (this.authService.validateStrengthPassword(newpassword)) {

                            // oldpassword wird in der db geprüft ob es richtig ist
                            // neue Passwort wird dann in der DB gespeichert und der User bleibt damit automatisch eingeloggt
                            this.authService.changePassword(username, oldpassword, newpassword).subscribe((changePasswordResponse) => {
                                if (changePasswordResponse && changePasswordResponse.changed && changePasswordResponse.changed === true) {
                                    // der User mit dem neuen Passwort sofort einlogen
                                    this.authService.signInUser(username, newpassword).subscribe((signInresponse) => {

                                        this.authService.isConnected = signInresponse !== null && signInresponse !== undefined && signInresponse.jwt;
                                        this.router.navigateByUrl('/');
                                        if (outputmessage && outputmessage.passwordChangedSuccess) {
                                            this.showModalDialog(true, outputmessage.passwordChangedSuccess, modalheight);
                                        }
                                        else {
                                            this.showModalDialog(false, outputmessage.defautMessage, modalheight);
                                        }

                                    }, (errorResponse) => {
                                        if (outputmessage && outputmessage.unknownUser)
                                            this.errorMessage = errorResponse.statusText + ': ' + errorResponse.error.message + outputmessage.unknownUser.replace('[[username]]', errorResponse.error.username);
                                        else
                                            this.showModalDialog(false, outputmessage.defautMessage, modalheight);
                                    }
                                    );
                                }
                            }, (changePasswordError) => {

                                if (changePasswordError && changePasswordError.error && changePasswordError.error.message) {
                                    this.showModalDialog(false, changePasswordError.error.message, '330px');
                                }
                                else {
                                    this.showModalDialog(false, outputmessage.defautMessage, modalheight);
                                }
                            }
                            );
                        }
                        // Fehler um unsicheres Passwort handeln
                        else {
                            if (outputmessage && outputmessage.unsecurePassword)
                                this.showModalDialog(false, outputmessage.unsecurePassword.replace('[[passwordMinLength]]', this.validatorPatternService.getPasswordMinLength.toString()), '310px');
                            else
                                this.showModalDialog(false, outputmessage.defautMessage, modalheight);
                        }
                    }
                    // Fehler um die Passwort-Regel handeln
                    else {
                        if (outputmessage && outputmessage.passwordsNotMatch)
                            this.showModalDialog(false, outputmessage.passwordsNotMatch, modalheight);
                        else
                            this.showModalDialog(false, outputmessage.defautMessage, modalheight);
                    }
                }
            });
    }

    /**
   *  Warnungsanzeige
   * @param passChangeForm, Formgroup 
   */
    showValidationwarningsModaldialog(passChangeForm: UntypedFormGroup) {
        const invalidFields = this.validationWarningService.getPasswordchangeFormWarning(passChangeForm);

        if (invalidFields && invalidFields.length > 0)
            this.validationWarningService.popupWarning(invalidFields);
    }

    /**
   * Vorgang-Abbruch
   */
    OnCancel() {
        this.authService.isUserTokenStillValid().subscribe(isTokenValid => {
            if (isTokenValid) {
                this.authService.isAdminUser.subscribe((isadminResponse) => {
                    // Default Ansicht auswählen
                    if (isadminResponse) {
                        this.router.navigateByUrl('datachangeprocessing');
                    }
                    else {
                        this.router.navigateByUrl('customerdata');
                    }
                });
            }
        });
    }

    /**
   * Modal-Anzeige
   * @param operationWasSuccessfull, gib an, ob die Operation erfolgreich war 
   * @param msg, Nachricht 
   * @param height, Höhe des Fensters 
   */
    showModalDialog(operationWasSuccessfull: boolean, msg: string, height: string) {
        let imessage: IMessage;
        if (operationWasSuccessfull) {
            imessage = {
                title: 'Bestätigung',
                message: msg,
                screen: 'confirmation',
                info: 0
            };
        } else {
            imessage = {
                title: 'Warnung',
                message: msg,
                screen: 'warning',
                info: 0
            };
        }

        this.dialog.open(PasswordchangeconfirmationComponent, {
            height: height,
            width: '550px',
            data: imessage
        });
    }
}

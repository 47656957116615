import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http'; 
import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
 
 
/**
  * Der Service liefert die Informationen eines Account des ÖWA Memberinterfaces zurück
  */
 
 @Injectable({
     providedIn: 'root'
 })
export class MemberService {
    private apiurl = environment.apiRootDirectory;
    constructor(private http: HttpClient) {
        
    }
     
    /**
      * liefert die Informationen eines Account des ÖWA Memberinterfaces zurück
      * @param principalDomain Mitgliedskürzel
      */
    public readMember(principalDomain: string): Observable<any> {
        return this.http.post<any>(this.apiurl + 'auth/member.php', { 'principalDomain': principalDomain });
         
    }
 
     
}

<h1 mat-dialog-title mat-dialog-draggable-title class="text-primary" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Warnung
    <button type="button" class="close" (click)="cancelOption()" data-dismiss="modal" aria-hidden="true">×</button>
</h1>
<div class="box">
<!--
    <h2>
        <p align="center">
            <span class="title">Warnung</span></p>
    </h2>
-->
    <main>
        <br>
        <i class="fa fa-exclamation-triangle fa-2x" aria-hidden="true" style="color:red"></i>
        &nbsp;&nbsp;{{message}}
        <br>
        <br>
        <br>
    </main>
    <footer>
        <p align="center">
            <button class="btn btn-outline-primary" (click)="cancelOption()">&nbsp;Schliessen</button></p>
    </footer>
</div>
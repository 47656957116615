
import { CryptoService } from './crypto.service';
import { Injectable } from '@angular/core';

/**
 * Der Service verschlüsselt und entschlüsselt die Daten
 */

@Injectable({
    providedIn: 'root'
})
export class DatastoreService {

    constructor(private cryptoService: CryptoService) {
    }

    /**
     * verschlüsselt und setzt ein Daten in localstorage
     * @param key, localstorage key
     * @param data, der Wert 
     */
    public setDataInLocalstorage(key: string, data: any) {
        localStorage.setItem(key, this.cryptoService.encryptData(data));
    }

    /**
     * lies ein Daten aus dem localstorage
     * @param key, localstorage key 
     */
    public getDataFromLocalstorage(key: any): any {

        // Debug Modus
        //return localStorage.getItem(key);

        return this.cryptoService.decryptData(localStorage.getItem(key));

    }
}
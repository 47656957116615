<nav id="topnav">
    <div id="brand">
        <div id="logo">
            <img src="../assets/oewalogotext.gif" alt="O&uml;WA" />
        </div>
        <div id="product">
            Mitgliederinterface
        </div>
    </div>
    <div id="profile-section">
        <div class="topnav-element" *ngIf="isLogged$ | async" routerLink="help">
            <i class="fa fa-question fa-2x" aria-hidden="true"></i>
        </div>
        <div class="topnav-element" *ngIf="isLogged$ | async" (click)="sendEmail()">
            <i class="fa fa-envelope fa-2x" aria-hidden="true"></i>
        </div>
        <div class="topnav-element" *ngIf="isLogged$ | async" routerLink="passwordchange">
            <i class="fa fa-key fa-2x" aria-hidden="true"></i>
        </div>
        <div id="profile" *ngIf="isLogged$">
            {{usertooltip$ | async}}
        </div>
        <div id="logout" class="topnav-element" *ngIf="isLogged$ | async" (click)="signOut()">
            <i class="fa fa-sign-out fa-2x" aria-hidden="true"></i>
        </div>
    </div>
</nav>
<nav id="subnav" *ngIf="principaldomain">
    <div class="subnav-element" routerLinkActive="active" routerLink="customerdata" *ngIf="isLogged$ | async">
        Stammdaten
    </div>
    <div class="subnav-element" routerLinkActive="active" [routerLink]="['offerlist', principaldomain]" *ngIf="isLogged$ | async">
        Angebote
    </div>
    <div class="subnav-element" routerLinkActive="active" routerLink="myaudit" *ngIf="principaldomain && !principaldomain.startsWith('a0') && !principaldomain.startsWith('a1') && !principaldomain.startsWith('a2') && isLogged$ | async">
        MyAudit
    </div>
    <div class="subnav-element" routerLinkActive="active" routerLink="membernews" *ngIf="isLogged$ | async">
        News
    </div>
    <div class="subnav-element" (click)="changesRegistrationDownloading()" *ngIf="isLogged$ | async">
        Änderungsantrag&nbsp;&nbsp;<i class="fa fa-download" aria-hidden="true"></i>
    </div>
    <div class="form-inline my-2 my-lg-0" *ngIf="(showMarketerDropdown | async)">
        <form [formGroup]="marketerGroupForm">
            <select formControlName="marketerGroupControl" class="custom-select mr-sm-2" [(ngModel)]="licencereceiver"
                (change)="onSelectionOfOfferFromMarketerGroupChanged()">
                <option [value]="licencereceiver" *ngFor="let licencereceiver of marketerGroupForm$ | async">
                    {{licencereceiver}}</option>
            </select>
        </form>
    </div>
    <div id="admin-controls" *ngIf="isAdmin$ | async">
        <div class="subnav-element" routerLinkActive="active" routerLink="datachangeprocessing" *ngIf="isLogged$ | async">
            Änderungsfreigabe
        </div>
        <div class="subnav-element" routerLinkActive="active" routerLink="admininputdata" *ngIf="isLogged$ | async">
            Datenpflege
        </div>
        <div class="form-inline" *ngIf="((isAdmin$ | async) || (connectedAccountIsMarketer | async))">
            <io-select [search]="true" *ngIf="availableDomains" size="compact" [value]="principaldomain" (selectedItemChange)="onSelectionOfPrincipalChanged($event)">
                <ng-container *ngFor="let domain of availableDomains">
                    <option [value]="domain">{{domain}}</option>
                </ng-container>
            </io-select>
        </div>
    </div>
</nav>

<sessioncountdown *ngIf="isLogged$ | async" [init]="tokenService.getRemainingTime()"></sessioncountdown>
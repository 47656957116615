import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationType } from '@io/web-components';

type LinkValidityResponse = {
    success: boolean,
    linkValid: boolean
}

type PasswordResetResponse = {
    success: boolean
}

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private http: HttpClient,
        private router: Router
    ) { }

    linkId: string;
    linkValidResponse: LinkValidityResponse;

    newPassword: string;
    newPasswordRepeat: string;

    private apiurl = environment.apiRootDirectory;

    async ngOnInit(): Promise<void> {
        this.linkId = this.route.snapshot.params.id;
        this.linkValidResponse = await this.verifyLinkValidity();
    }

    async verifyLinkValidity(): Promise<LinkValidityResponse> {
        return await this.http.get<LinkValidityResponse>(`${this.apiurl}/data/password/check.php?l=${this.linkId}`).toPromise();
    }

    goToLogin() {
        this.router.navigate(['/signin']);
    }

    getPasswordErrorHandlingSettings() {
        if (this.newPassword !== this.newPasswordRepeat) {
            return {
                invalid: true,
                message: 'Die Passwörter stimmen nicht überein.'
            };
        } else {
            return {
                invalid: false,
            };
        }
    }

    updateValue(event: Event & { detail?: { value: string } }) {
        this[event.target['id']] = event.detail;
    }

    async setNewPassword() {
        if (this.newPassword === this.newPasswordRepeat) {
            const result = await this.http.post<PasswordResetResponse>(`${this.apiurl}/data/password/reset.php`, {
                newPassword: this.newPassword,
                link: this.linkId
            }).toPromise();

            if (result.success) {
                this.newPassword = '';
                this.newPasswordRepeat = '';
                const event = new CustomEvent('io.sendNotification',
                    {
                        detail: [
                            {
                                icon: 'check',
                                type: NotificationType.positive,
                                title: 'Erfolg',
                                text: 'Ihr Passwort wurde erfolgreich geändert. Sie werden nun zur Anmeldung weitergeleitet.',
                                duration: 8000,
                                dismissable: true
                            }
                        ]
                    });
                document.dispatchEvent(event);
                setTimeout(() => {
                    this.router.navigate(['/signin']);
                }, 1500);
            } else {
                const event = new CustomEvent('io.sendNotification',
                    {
                        detail: [
                            {
                                icon: 'error',
                                type: NotificationType.negative,
                                title: 'Fehler',
                                text: 'Ihre Anfrage konnte nicht bearbeitet werden. Bitte versuchen Sie es später erneut.',
                                duration: 8000,
                                dismissable: true
                            }
                        ]
                    });
                document.dispatchEvent(event);
            }
        }
    }
}

<div class="container">
    <io-card>
        <io-card-header>
            <io-heading level="h2" text="Anmelden"></io-heading>
        </io-card-header>
        <io-card-body>
            <form [formGroup]="signInForm">
                <io-input [required]="true" id="username" [value]="signInForm.controls.username.value" (valueChanged)="updateFormValue($event)" label="Benutzerkennung"></io-input>
                <io-input [required]="true" id="password" type="password" [value]="signInForm.controls.password.value" (valueChanged)="updateFormValue($event)" label="Passwort"></io-input>
            </form>
        </io-card-body>
        <io-card-footer>
            <io-button (click)="popupAccountrecoveryComponent()" appearance="flat">Ich benötige ein neues Passwort</io-button>
            <io-button (click)="onSubmit()" appearance="primary" [disabled]="signInForm.invalid">Anmelden</io-button>
        </io-card-footer>
    </io-card>
</div>
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
    selector: 'draggable-modal',
    templateUrl: './draggable-modal.component.html',
    styleUrls: ['./draggable-modal.component.css']
})
export class DraggableModalComponent {

    constructor(public dialogRef: MatDialogRef<DraggableModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

    cancelOption() {
        this.dialogRef.close();
    }
}
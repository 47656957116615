import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';

/**
 * lies die core.php und stellt die vorhanden Konfigurationsdaten zur Verfügung
 */

@Injectable({
    providedIn: 'root'
})
export class ApiCoreService {
    private apiurl = environment.apiRootDirectory;

    constructor(private http: HttpClient) { }

    //lies die core.php und stellt die vorhanden Konfigurationsdaten zur Verfügung
    public readCore(): Observable<any> {
        return this.http.get<any>(this.apiurl + 'data/common/readCore.php');
    }
}
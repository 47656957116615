import { StringFunctionsService } from './stringFunctions.service';
import { contactTypes } from '../enumerations/contactTypes.enum';
import { changesTypes } from '../enumerations/changeTypes.enum';
import { CryptoService } from './crypto.service';
import { Field } from '../models/Field.model';
import { Injectable } from '@angular/core';

/**
 * Der Service gleich die Daten ab
 */

@Injectable({
    providedIn: 'root'
})
export class DataComparatorService {

    constructor(private stringService: StringFunctionsService,
        private cryptoservice: CryptoService) { }


    // zum Abgleich der Kontaktdaten
    /**
     * gleicht die Kontaktdaten ab
     * @param oldvalue, alte Wert 
     * @param newvalue, neuer Wert 
     * @param pendingvalue, noch nicht bestätiger Wert 
     * @param contactType, Kontaktyp 
     * @param fieldnameInTable, Tabellenspalte ind der Dantenbank 
     * @param fielddescription, Feldbeschreibung 
     */
    public compareContactFieldValues(oldvalue: string, newvalue: string, pendingvalue: string, contactType: contactTypes, fieldnameInTable: string, fielddescription: string) {

        return this.compareOldAndNewValuesByField(oldvalue, newvalue, pendingvalue, fieldnameInTable, fielddescription, contactType);
    }


    /**
     * 
       * gleicht die Ddaten ab
        * @param oldvalue, alte Wert 
        * @param newvalue, neuer Wert 
        * @param pendingvalue, noch nicht bestätiger Wert         
        * @param fieldnameInTable, Tabellenspalte ind der Dantenbank 
        * @param fielddescription, Feldbeschreibung 
     */
    public compareFieldValues(oldvalue: string, newvalue: string, pendingvalue: string, fieldnameInTable: string, fielddescription: string) {

        if (fieldnameInTable === 'ftp_password') {

            // indicative Werte entfernen
            if (oldvalue === changesTypes.inserted)
                oldvalue = '';

            if (newvalue === changesTypes.deleted)
                newvalue = '';

        }       

        return this.compareOldAndNewValuesByField(oldvalue, newvalue, pendingvalue, fieldnameInTable, fielddescription);
    }
    

    /**
     * gleicht die Kontaktdaten ab
     * @param oldvalue, alte Wert 
     * @param newvalue, neuer Wert 
     * @param pendingvalue, noch nicht bestätiger Wert     
     * @param fieldnameInTable, Tabellenspalte ind der Dantenbank 
     * @param fielddescription, Feldbeschreibung 
     * @param contactType, Kontaktyp 
     */
    private compareOldAndNewValuesByField(oldvalue: string, newvalue: string, pendingvalue: string, fieldnameInTable: string, fielddescription: string, contactType?: contactTypes) {

        let result: Field = null;

        if (!this.stringService.isUndefinedNullOrEmpty(newvalue) && !this.stringService.isUndefinedNullOrEmpty(pendingvalue) && pendingvalue === newvalue)
            return result;

        if (!this.stringService.isUndefinedNullOrEmpty(newvalue) && !this.stringService.isUndefinedNullOrEmpty(oldvalue)) {
            // Falls eine Änderung ist 
            if (newvalue !== oldvalue) {
                if (contactType)
                    result = new Field(fielddescription, fieldnameInTable, oldvalue, newvalue, contactType.toString());
                else
                    result = new Field(fielddescription, fieldnameInTable, oldvalue, newvalue);
            }
            // Falls die Änderung ein Rollback ist. Der User möchte die noch nicht genehmigte Änderung zurüchnehmen. Die alte Daten gilt aber weiter
            else if (!this.stringService.isUndefinedNullOrEmpty(pendingvalue)) {
                if (oldvalue === newvalue)
                    if (contactType)
                        result = new Field(fielddescription, fieldnameInTable, oldvalue, changesTypes.rollback, contactType.toString());
                    else
                        result = new Field(fielddescription, fieldnameInTable, oldvalue, changesTypes.rollback);
            }
        }
        // Falls die Änderung einen neuen Eintrag ist. 
        else if (!this.stringService.isUndefinedNullOrEmpty(newvalue) && (this.stringService.isUndefinedNullOrEmpty(oldvalue))) {
            if (contactType)
                result = new Field(fielddescription, fieldnameInTable, changesTypes.inserted, newvalue, contactType.toString());
            else

                result = new Field(fielddescription, fieldnameInTable, changesTypes.inserted, newvalue);
        }
        // Falls die Änderung eine Löschung ist. Der User möchte die SCHON genehmigte Änderung löschen
        else if (!this.stringService.isUndefinedNullOrEmpty(oldvalue) && this.stringService.isUndefinedNullOrEmpty(pendingvalue) && (this.stringService.isUndefinedNullOrEmpty(newvalue))) {
            if (contactType)
                result = new Field(fielddescription, fieldnameInTable, oldvalue, changesTypes.deleted, contactType.toString());
            else
                result = new Field(fielddescription, fieldnameInTable, oldvalue, changesTypes.deleted);
        }
        // Falls die Änderung ein Rollback ist. Der User möchte die noch nicht genehmigte Änderung zurüchnehmen. Die alte  bleibt in DB und gilt
        else if (!this.stringService.isUndefinedNullOrEmpty(oldvalue) && !this.stringService.isUndefinedNullOrEmpty(pendingvalue) && (this.stringService.isUndefinedNullOrEmpty(newvalue))) {
            if (contactType)
                result = new Field(fielddescription, fieldnameInTable, oldvalue, changesTypes.rollback, contactType.toString());
            else
                result = new Field(fielddescription, fieldnameInTable, oldvalue, changesTypes.rollback);
        }
        // Falls die Änderung einen neuen Eintrag ist und Der User sie zurüchnehmen möchte. 
        else if (this.stringService.isUndefinedNullOrEmpty(oldvalue) && !this.stringService.isUndefinedNullOrEmpty(pendingvalue) && (this.stringService.isUndefinedNullOrEmpty(newvalue))) {

            if (contactType)
                result = new Field(fielddescription, fieldnameInTable, oldvalue, changesTypes.rollback, contactType.toString());
            else{
                result = new Field(fielddescription, fieldnameInTable, oldvalue, changesTypes.rollback);                
            }
        }
        

        if (fieldnameInTable === 'ftp_password') {
            //ftpPassword generieren lassen
            //console.log(this.cryptoservice.encryptData(this.stringService.trimmed("meineftpPassword")))
            //generierte ftppassword entschlüsseln
            //console.log(this.cryptoservice.decryptData(this.stringService.trimmed("U2FsdGVkX19X2qpxrg86OhEKmtKVZD41qMkA3JACc98=")))                        

            if (result) {
                result.newvalue = this.cryptoservice.encryptData(this.stringService.trimmed(newvalue));
                result.oldvalue = this.cryptoservice.encryptData(this.stringService.trimmed(oldvalue));
                result.newvalueBeforeHash = this.stringService.trimmed(newvalue);
            }
        }
    
        return result;
    }

}
/**
 * Aufzählungen von Kontakttypen
 */
export enum contactTypes {
    'organisation' = 1,
    'technical' = 2,   
    'auditreport' = 3,
    'reportOfficer' = 4,
    'firstReportRecipient' = 5,
    'secondReportRecipient' = 6,
    'thirdReportRecipient' = 7,
}
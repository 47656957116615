import { Field } from './Field.model';

/**
 * Modelklasse der Datenänderung
 */
export class UserDataChanges {

    constructor(public principalDomain: string,
        public dataCategorieId: string,
        public changeDate: string,
        public tablename: string,
        public processed: string,
        public fields: Field[],
        public tableFieldidentity?: string,
        public offername?: string,
        public offerRowId?: number) {            
    }

    // Details der änderungen für EMail-Body wenn Oewa informiert werden soll, dass eine Änderung stattgefunden hat
    public getdetails(): string {
        const strings: string[] = [];
        if (this.fields != null && this.fields != undefined){
            for (const field of this.fields) {
                if (strings.indexOf(field.changedFieldDesc) < 0) {
                    strings.push(field.changedFieldDesc);
                }
            }
            if (strings.length > 0)
                return strings.join(', ');
        }
        return null;
    }
}
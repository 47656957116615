import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import data from 'src/assets/config.json';

/**
 * Der Service liefert die Konfigurationsdaten zurück
 */


@Injectable({
    providedIn: 'root'
})
export class AppSettingsService {

    constructor(private http: HttpClient) {

    }

    // lies die Konfiguration
    public getJSONConfig(): Observable<any> {
        return this.http.get('./assets/config.json');
    }

    // lies die Anzeigedauer des Modaldialogs
    public getModalDisplayingtime() {

        if (data && data.configuration) {
            if (data.configuration.warningtimes && data.configuration.warningtimes.noSignificantChange) {
                return data.configuration.warningtimes.noSignificantChange;
            }
            return 7;
        }
    }

}
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * Der Service liefert die Meldungen zurück
 */


@Injectable()
export class MembernewsService {


    private apiurl = environment.apiRootDirectory;


    constructor(private _http: HttpClient) { }

    /**
   *  lies die meldungen zurück
   */
    readMembernews() {

        return this._http.get<any>(this.apiurl + 'membernews/readMembernews.php');

    }

}
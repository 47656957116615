import { environment } from 'src/environments/environment';
import { Customer } from 'src/app/models/Customer.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * Der Service spricht das API an, und liefert die Kontakdaten zurück
 */

@Injectable({
    providedIn: 'root'
})
export class ContactService {

    private apiurl = environment.apiRootDirectory;


    constructor(private _http: HttpClient) { }
  
    /**
   * lies die Kontaktdaten
   * @param principalDomain, Mitgliedskürzel 
   */
    readContacts(principalDomain: string) {
        return this._http.post<Customer[]>(this.apiurl + 'data/contacts/readContactsByCustomer.php', { 'principalDomain': principalDomain });

    }

  
    /**
   * aktualisiert das Feld Angebotsname in der Kontakttabelle
   * @param oldOffername, alter Angebotsname 
   * @param newOffername, neuer Angebotsname 
   * @param principalDomain, Mitgliedskürzel 
   */
    /*
   auskommentiert 07022020
   offername bedeutet nicht Angebotsname nun eine Kennung ist, d.h. eindeutig
   websitename ist der neue Angebotsname und ist NICHT in contacts sowie locallists verwenden

  updateContact(oldOffername: string, newOffername: string, principalDomain: string) {
    return this._http.post<any>(this.apiurl + "data/contacts/updateContactsByQuery.php", {
      "oldoffername": oldOffername,
      "newoffername": newOffername,
      "principalDomain": principalDomain});

  }
  */

}

/**
 *  Modelklasse eines geänderten Datenbankfeldes
 *  
 * */
export class Field {

    constructor(
        public changedFieldDesc: string, // Beschreibung des Felds
        public fieldname: string, // Name des Felds in der DB
        public oldvalue: string,  // alter Wert
        public newvalue: string,  // neuer Wert. Kann ein Hash sein, Falls es sich um ein gehashtes Feld handelt        
        public fieldidentityValue?: string, // Fremdschlüssel-Wert der KontaktTabelle
        public newvalueBeforeHash?:string // neuer Wert (bevor der Verschlüsselung) Falls es sich um ein gehashtes Feld handelt
        
    ) { }
}

import { StringFunctionsService } from './stringFunctions.service';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';
import { CryptoService } from './crypto.service';
import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class TokenService {

    apiurl = environment.apiRootDirectory;

    /**
   * Konstruktor
   * @param _http HttpClient Objekt
   * @param cryptoService Angular CryptoJs Objekt
   * @param stringService String Service
   */
    constructor(private _http: HttpClient, private cryptoService: CryptoService, private stringService: StringFunctionsService) { }

    /**
   * setzt eine Eingabe in der localstorage
   * @param accountInfo die Eingabe
   */
    public setTokenInStorage(accountInfo: any) {  

        if (accountInfo) {
            if (accountInfo.isAdmin)
                accountInfo.isAdmin = this.cryptoService.encryptData(accountInfo.isAdmin);

            if (!this.stringService.isUndefinedNullOrEmpty(accountInfo.isMarketer))
                accountInfo.isMarketer = this.cryptoService.encryptData(accountInfo.isMarketer);

            if (accountInfo.email)
                accountInfo.email = this.cryptoService.encryptData(accountInfo.email);

            if (!this.stringService.isUndefinedNullOrEmpty(accountInfo.hasOA))
                accountInfo.hasOA = this.cryptoService.encryptData(accountInfo.hasOA);

            if (accountInfo.username)
                accountInfo.username = this.cryptoService.encryptData(accountInfo.username);

            if (accountInfo.principalDomain)
                accountInfo.principalDomain = this.cryptoService.encryptData(accountInfo.principalDomain);

            if (accountInfo.accounttype)
                accountInfo.accounttype = this.cryptoService.encryptData(accountInfo.accounttype);

            if (!this.stringService.isUndefinedNullOrEmpty(accountInfo.isMobilUser))
                accountInfo.isMobilUser = this.cryptoService.encryptData(accountInfo.isMobilUser);

            if (!this.stringService.isUndefinedNullOrEmpty(accountInfo.isAvdertisingprovider))
                accountInfo.isAvdertisingprovider = this.cryptoService.encryptData(accountInfo.isAvdertisingprovider);

            if (accountInfo.JwtExpireAt)
                accountInfo.JwtExpireAt = this.cryptoService.encryptData(accountInfo.JwtExpireAt);

            localStorage.setItem('loggedInUser', JSON.stringify(accountInfo));
        }
    }

    /**
   * lies der eingelogte User
   */
    public getLoggedInUser() {

        const accountInfo = JSON.parse(localStorage.getItem('loggedInUser'));
        if (accountInfo) {
            if (accountInfo.isAdmin)
                accountInfo.isAdmin = this.cryptoService.decryptData(accountInfo.isAdmin);

            if (!this.stringService.isUndefinedNullOrEmpty(accountInfo.isMarketer))
                accountInfo.isMarketer = this.cryptoService.decryptData(accountInfo.isMarketer);

            if (accountInfo.email)
                accountInfo.email = this.cryptoService.decryptData(accountInfo.email);

            if (accountInfo.username)
                accountInfo.username = this.cryptoService.decryptData(accountInfo.username);

            if (!this.stringService.isUndefinedNullOrEmpty(accountInfo.hasOA))
                accountInfo.hasOA = this.cryptoService.decryptData(accountInfo.hasOA);

            if (accountInfo.principalDomain)
                accountInfo.principalDomain = this.cryptoService.decryptData(accountInfo.principalDomain);

            if (accountInfo.JwtExpireAt)
                accountInfo.JwtExpireAt = this.cryptoService.decryptData(accountInfo.JwtExpireAt);

            if (accountInfo.accounttype)
                accountInfo.accounttype = this.cryptoService.decryptData(accountInfo.accounttype);

            if (!this.stringService.isUndefinedNullOrEmpty(accountInfo.isMobilUser))
                accountInfo.isMobilUser = this.cryptoService.decryptData(accountInfo.isMobilUser);

            if (!this.stringService.isUndefinedNullOrEmpty(accountInfo.isAvdertisingprovider))
                accountInfo.isAvdertisingprovider = this.cryptoService.decryptData(accountInfo.isAvdertisingprovider);
        }

        return JSON.stringify(accountInfo);
    }


    /**
   * git an, ob ein Token vorliegt
   */
    public jwtokenExists() {
        return this.getLoggedInUser() !== 'null' && this.getLoggedInUser() !== null && this.getLoggedInUser() !== undefined && JSON.parse(this.getLoggedInUser()).jwt;
    }

    /**
   * gib an, ob ein Token valid ist
   */
    public isTokenValid() {
        return this._http.post<any>(this.apiurl + 'auth/validate_token.php', this.getLoggedInUser());
    }


    /**
   * liefert die Anzahl von Minuten zwischen zwei Datetime
   * @param dt2 Datetime Parameter
   * @param dt1 Datetime Parameter
   */
    public getMinutesBetweenDates(dt2: Date, dt1: Date) {

        let diff = (dt2.getTime() - dt1.getTime()) / 1000;
        diff /= 60;
        return Math.abs(Math.round(diff));

    }


    /**
   * gib die verbleibende Minute vor dem Timeout
   */
    public getRemainingTime() {
        if (this.jwtokenExists() && this.isJwtTokenValid()) {
            const token = JSON.parse(this.getLoggedInUser());

            const helper = new JwtHelperService();

            const expirationDate = helper.getTokenExpirationDate(token.jwt);

            return this.getMinutesBetweenDates(expirationDate, new Date());
        }
        return 0;
    }


    /**
   * gib an, ob ein Token noch valid ist
   */
    public isJwtTokenValid() {

        const token = JSON.parse(this.getLoggedInUser());

        if (token === null || token === undefined || token == null) {
            return false;
        }

        const helper = new JwtHelperService();

        const isExpired = helper.isTokenExpired(token.jwt);

        return !isExpired;

    }


}
